import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import moment from "moment";
import * as api from "../../../api";
import Loader from "../../reusables/Loader";

function ClockOut({
  clockInData,
  setClockIn,
  getClockInStatusLoader,
  // authState,
}) {
  // const { authState } = useOktaAuth();
  const authState = JSON.parse(localStorage.getItem("auth0-token-storage"));
  const accessToken = authState?.access_token;
  const [popupVisible, setPopupVisible] = useState(false);

  const [postData, setPostData] = useState({
    facilityId: 380,
    latitude: 51.16570369999999, // latitude of agintil
    longitude: -0.7552394, // longitude of agintil
  });
  const [loader, setLoader] = useState(false);

  const today = new Date();
  const history = useHistory();

  const clockInTime = moment(clockInData.TimeStampUtc);

  const d = new Date(clockInTime);

  let diffMs = today.getTime() - d.getTime(); // difference in milliseconds
  let hoursRendered = Math.floor(diffMs / (1000 * 60 * 60)); // difference in hours
  let minutesRendered = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60)); //

  const formatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const currentDateTime = today.toLocaleDateString("en-US", formatOptions);
  const [time, setTime] = useState("");
  let interval;

  const updateTime = () => {
    interval = setInterval(() => {
      const currentTime = new Date().toLocaleTimeString();
      setTime(currentTime);
    }, 1000);
  };

  useEffect(() => {
    // clockInStatus();
    updateTime();
    return () => {
      clearInterval(interval);
    };
  }, []);

  const submitClockOut = async (e) => {
    e.preventDefault();
    let _postData = {};
    setLoader(true);

    function getPosition(pos) {
      _postData.latitude = pos.coords.latitude;
      _postData.longitude = pos.coords.longitude;
    }

    if (navigator.geolocation) {
      _postData = {
        facilityId: 380,
        latitude: null,
        longitude: null,
      };

      navigator.geolocation.getCurrentPosition(getPosition);
    }

    if (!authState || !authState.access_token) return;
    try {
      const clockOut = await api.postClockInAndOut(_postData, accessToken);
      setClockIn((prev) => !prev);
      console.log("Clocked Out Successfully!", clockOut);
      localStorage.removeItem("facility");
      history.push("/landing-page");
    } catch (error) {
      console.log("Error Clocking Out", error);
      if (
				error?.response?.status === 401 &&
				error?.response?.data?.errors?.length > 0 &&
				error?.response?.data?.errors[0]?.message === "Invalid Token"
			) {
				handleExpiration();
			}
    } finally {
      setLoader(false);
    }
  };

  // useEffect(async () => {
  //   if (postData.facilityId !== 0) {
  //     await submitClockOut();
  //   }
  // }, [postData]);

  const handleCancel = (e) => {
    e.preventDefault();
    setPopupVisible(false);
  };

  const handleExpiration = () => {
		localStorage.removeItem("auth0-token-storage");
		localStorage.removeItem("user-claims");
		history.push("/login");
	};

  return (
    <>
      <div className="d-flex justify-content-center pr-2 ml-lg-4">
        {/* <div className="popup-content bg-white p-2"></div> */}
        <div className="clinician-clock d-flex flex-column py-2">
          <div className="py-2 text-center">
            <button
              className="clock-out-button rounded-lg"
              // onClick={() => {
              //   if (popupVisible) {
              //     setPopupVisible(false);
              //   } else {
              //     setPopupVisible(true);
              //   }
              // }}
              onClick={async (e) => submitClockOut(e)}
            >
              {loader || getClockInStatusLoader ? (
                <div className="d-inline">
                  <Loader variant="light" />
                </div>
              ) : (
                "Clock Out"
              )}
            </button>
          </div>
        </div>

        {/* <div
          className={
            popupVisible === true
              ? "popup-container position-absolute d-flex flex-column show-popup"
              : "popup-container"
          }
          style={{
            position: "relative",
            zIndex: 9999
          }}>
          <form
            style={{
              position: "relative",
              zIndex: 9999
            }}>
            <div className="d-flex align-items-center justify-content-between pb-2">
              <span className="popup-header landing-link orange-1 font-weight-bold">
                {currentDateTime} {time}
              </span>
            </div>
            <div className="popup-note d-flex flex-column pb-3">
              <p>
                Clocking out will render you {hoursRendered === 0 ? hoursRendered : hoursRendered - 1} hour(s) and{" "}
                {minutesRendered} mins.
              </p>
              <p className="orange-1 font-weight-bold">
                Do you wish to continue?
              </p>
            </div>
            <div className="text-left pt-1 pl-3 d-flex justify-content-end my-2">
              <button
                className="mr-2 btn btn-secondary px-3"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary px-4"
                onClick={async (e) => await submitClockOut(e)}
              >
                Okay
              </button>
            </div>
          </form>
        </div> */}
      </div>
    </>
  );
}

export default ClockOut;
