import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as api from "../../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";

const LovedOneRequest = () => {
  const authState = JSON.parse(localStorage.getItem("auth0-token-storage"));
  const accessToken = authState?.access_token;
  const history = useHistory();

  const [pendingLovedOnes, setPendingLovedOnes] = useState([]);
  const [forApprovalData, setForApprovalData] = useState([]);
  const [lovedOneLoader, setLovedOneLoader] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);

  const handleClose = () => {
    setShowApproveModal(false);
    setLovedOneLoader(false);
  };

  useEffect(() => {
    const pendingLovedOnesRequest = async () => {
      try {
        setLovedOneLoader(true);
        const res = await api.getPendingLovedOneRequest(accessToken);
        setPendingLovedOnes(res.data.data);
        setLovedOneLoader(false);
      } catch (error) {
        console.log(error);
        setLovedOneLoader(false);
        if (
          error?.response?.status === 401 &&
          error?.response?.data?.errors?.length > 0 &&
          error?.response?.data?.errors[0]?.message === "Invalid Token"
        ) {
          handleExpiration();
        }
      }
    };
    pendingLovedOnesRequest();
  }, []);

  const approveLovedOneRequest = (data) => {
    setForApprovalData(data);
    setShowApproveModal(true);
  };

  const saveLovedOne = async () => {
    const data = {
      id: forApprovalData._id,
    };
    try {
      setLovedOneLoader(true);
      const res = await api.approveLovedOneRequest(data, accessToken);
      console.log(res.data.data);
      Swal.fire({
        title: "Success",
        html: "Three random words sent to email.",
        icon: "success",
        confirmButtonColor: "#833f84",
      });
      setShowApproveModal(false);
      setLovedOneLoader(false);
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "Error!",
        html: error.response.data.errors[0].message,
        icon: "error",
        confirmButtonColor: "#833f84",
      });
      setLovedOneLoader(false);
      if (
				error?.response?.status === 401 &&
				error?.response?.data?.errors?.length > 0 &&
				error?.response?.data?.errors[0]?.message === "Invalid Token"
			) {
				handleExpiration();
			}
    }
  };

  const handleExpiration = () => {
    localStorage.removeItem("auth0-token-storage");
    localStorage.removeItem("user-claims");
    history.push("/login");
  };

  return !accessToken ? (
    handleExpiration()
  ) : (
    <>
      <div className="container">
        <div
          className="orange-bg-1 text-light py-3 my-0 d-flex justify-content-between align-items-center align-content-center align-self-center"
          style={{
            borderTopLeftRadius: "25px",
            borderTopRightRadius: "25px",
          }}
        >
          {/* <FontAwesomeIcon icon={["fa", "user"]} className="mr-2 " /> */}
          <div className="d-flex justify-content-between">
            <h5 className="ml-3 text-light" style={{ fontWeight: "500" }}>
              Pending Adding Lovedones Request
            </h5>
          </div>
        </div>
        <div
          className="p-0 bg-white border"
          style={{
            borderBottomLeftRadius: "25px",
            borderBottomRightRadius: "25px",
            backgroundColor: "white",
          }}
        >
          <div
            className="mx-0 px-2 py-3"
            style={{
              minHeight: "240px",
            }}
          >
            <div className="mx-2">
              {pendingLovedOnes && pendingLovedOnes.length > 0 ? (
                pendingLovedOnes.map((data, index) => (
                  <div
                    className={`${
                      pendingLovedOnes.length > 1 &&
                      pendingLovedOnes.length - 1 !== index
                        ? "border-bottom"
                        : ""
                    } px-2 mb-2 pb-3`}
                    key={index}
                  >
                    <div className="d-flex justify-content-between">
                      <p className="orange-1 font-weight-bold">
                        {data.FirstName + " " + data.LastName}
                      </p>
                      <p className="orange-1 font-weight-bold">
                        {data.facilityName.split("-")[0]}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <p className="font-weight-bold">
                          {data.requestorFirstName.charAt(0).toUpperCase() +
                            data.requestorFirstName.slice(1) +
                            " " +
                            data.requestorLastName.charAt(0).toUpperCase() +
                            data.requestorLastName.slice(1)}
                        </p>
                        <p>{data.requestorEmail}</p>
                      </div>
                      <div
                        className="align-self-center"
                        onClick={() => approveLovedOneRequest(data)}
                      >
                        <FontAwesomeIcon
                          icon={["fa", "check"]}
                          className="mr-2 orange-1"
                          style={{
                            height: "25px",
                            width: "25px",
                            cursor: "pointer",
                          }}
                          data-toggle="tooltip"
                          data-placement="top"
                          title={`Approve ${
                            data.requestorFirstName.charAt(0).toUpperCase() +
                            data.requestorFirstName.slice(1) +
                            " " +
                            data.requestorLastName.charAt(0).toUpperCase() +
                            data.requestorLastName.slice(1)
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <div
                    className="h-100 w-100 align-items-center d-flex text-center justify-content-center"
                    style={{
                      minHeight: "240px",
                      fontSize: "20px",
                      letterSpacing: "1px",
                      fontWeight: "bolder",
                      opacity: 0.2,
                      backgroundColor: "rgba(0, 0, 0, 0.07)",
                      borderRadius: "25px",
                    }}
                  >
                    No Pending LovedOnes
                  </div>
                </>
              )}
            </div>

            {/* <div className="d-flex justify-content-center mt-4">
                  <div className="text-center mx-2">
                    <button
                      className="border-0 text-light orange-bg-1 p-2"
                      style={{ borderRadius: 15, width: 100 }}
                      onClick={saveLovedOne}
                    >SAVE</button>
                  </div>
                </div> */}
          </div>
        </div>
      </div>
      <Modal show={showApproveModal} onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title className="orange-1">
            {forApprovalData?.FirstName + " " + forApprovalData?.LastName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              className="mb-3 text-left"
              controlId="exampleForm.ControlInput1"
            >
              <p>
                Are you sure do you want to accept{" "}
                {forApprovalData?.requestorFirstName?.charAt(0).toUpperCase() +
                  forApprovalData?.requestorFirstName?.slice(1) +
                  " " +
                  forApprovalData?.requestorLastName?.charAt(0).toUpperCase() +
                  forApprovalData?.requestorLastName?.slice(1)}{" "}
                as lovedone?
              </p>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button variant="primary" onClick={saveLovedOne}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LovedOneRequest;
