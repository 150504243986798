import React from "react";
import { Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as api from "../../../api";
import moment from "moment";
import usePracticeHook from "../../../practice";
import userRoles from "../../../role";

const superAdminFacilitySchedulesData = [
  {
    facilityId: 351,
    facilityName: "Agincare Homes Fulford",
    podiatryServices: {
      podiatristName: "Aries Resurreccion",
      podiatrySchedule: [
        {
          FirstName: "Jeffrey",
          LastName: "Gomez",
          KnownAs: "Jeff",
          WardFloorRoom: "2",
          DateOfBirth: "1976-06-14",
          DaysSinceLastTreatment: 52,
          HasConsentOnFile: false,
          PaymentType: "Facility Funding",
          AssessmentDue: true,
          Notes: "My Notes 1",
        },
        {
          FirstName: "John",
          LastName: "Doe",
          KnownAs: "John",
          WardFloorRoom: "21",
          DateOfBirth: "1956-07-12",
          DaysSinceLastTreatment: 152,
          HasConsentOnFile: true,
          PaymentType: "Facility Funding",
          AssessmentDue: true,
          Notes: "My Notes 2",
        },
      ],
    },
    physioServices: {
      physioName: "Kirstin Wilkinson",
      physioSchedule: [
        {
          FirstName: "Jeffrey",
          LastName: "Gomez",
          KnownAs: "Jeff",
          WardFloorRoom: "2",
          DateOfBirth: "1976-06-14",
          DaysSinceLastTreatment: 52,
          HasConsentOnFile: false,
          PaymentType: "Facility Funding",
          AssessmentDue: true,
          Notes: "My Notes 1",
        },
      ],
    },
    dieteticsServices: {
      dietitianName: "Louise Bula",
      dieteticsSchedule: [
        {
          FirstName: "Jeffrey",
          LastName: "Gomez",
          KnownAs: "Jeff",
          WardFloorRoom: "2",
          DateOfBirth: "1976-06-14",
          DaysSinceLastTreatment: 52,
          HasConsentOnFile: false,
          PaymentType: "Facility Funding",
          AssessmentDue: true,
          Notes: "My Notes 2",
        },
      ],
    },
  },
  {
    facilityId: 358,
    facilityName: "Agincare Homes Tilford",
    podiatryServices: {
      podiatristName: "Aries Resurreccion",
      podiatrySchedule: [{}],
    },
    physioServies: {
      physioName: "Kirstin Wilkinson",
      physioSchedule: [{}],
    },
    dieteticsServices: {
      dietitianName: "Louise Bula",
      dietitianSchedule: [{}],
    },
  },
];

const DashboardSummary = ({ scheduleData }) => {
  const authState = JSON.parse(localStorage.getItem("auth0-token-storage"));
  const accessToken = authState?.access_token;
  const { hasPhysioPractice } = usePracticeHook();
  const { adminRole } = userRoles();
  const dateToday = moment(new Date()).format("DD_MM_YYYY");

  // const newPatient = scheduleData.map((patient) =>
  //   patient.ResidentsToBeTreated.filter((a) => {
  //     return a.CreatedOn.split("T")[0];
  //   })
  // );

  // const filteredDateCreated = newPatient[0].filter(
  //   (d) => d.CreatedOn.split("T")[0] === moment(new Date()).format("YYYY-MM-DD")
  // );

  const downloadTable = async (facilityId) => {
    const doc = new jsPDF("l", "pt");
    try {
      let _allPatients = await api.fetchPatients(accessToken, null);

      _allPatients = _allPatients.data.data.data.filter(
        (facility) => facility.FacilityId === facilityId
      );
      const tableData = _allPatients[0]?.ResidentsToBeTreated ?? [];

      autoTable(doc, {
        headStyles: {
          fillColor: [243, 105, 23],
          minCellHeight: 30,
          valign: "middle",
          halign: "center",
          fontSize: 8,
        },
        columnStyles: {
          10: { cellWidth: 80 },
        },
        styles: { fontSize: 9, cellPadding: 0.5, overflow: "linebreak" },
        head: [
          [
            "#",
            "First Name",
            "Last Name",
            "Known As",
            "Ward/Floor/Room",
            "Date of Birth",
            "Consent on file",
            "Payment Type",
            "Weeks Since Treatment",
            "AssessDue",
            "Treat (Circle one)",
            "Notes",
          ],
        ],
        body: tableData.map((patient, index) => [
          { content: index + 1, colSpan: 1, rowSpan: 1 },
          { content: patient.FirstName, colSpan: 1, rowSpan: 1 },
          { content: patient.LastName, colSpan: 1, rowSpan: 1 },
          { content: patient.KnownAs ?? "", colSpan: 1, rowSpan: 1 },
          {
            content: patient.WardName ?? "" + " / " + patient.RoomNumber ?? "",
            colSpan: 1,
            rowSpan: 1,
          },
          {
            content: patient?.DateOfBirth
              ? moment(patient.DateOfBirth).format("MMM-D-YYYY")
              : ""
              ? Math.floor(parseInt(patient.DaysSinceLastTreatment) / 7)
              : "",
            colSpan: 1,
            rowSpan: 1,
          },
          {
            content: patient.HasConsentOnFile === true ? "yes" : "no",
            colSpan: 1,
            rowSpan: 1,
          },
          { content: patient.PaymentType ?? "", colSpan: 1, rowSpan: 1 },
          {
            content: patient?.DaysSinceLastTreatment
              ? Math.floor(parseInt(patient.DaysSinceLastTreatment) / 7)
              : "",
            colSpan: 1,
            rowSpan: 1,
          },
          {
            content: patient.AssessmentDue === true ? "yes" : "no",
            colSpan: 1,
            rowSpan: 1,
          },
          { content: "Yes / No", colSpan: 1, rowSpan: 1 },
          { content: "", colSpan: 1, rowSpan: 1 },
        ]),
        margin: { top: 20, left: 20, right: 20, bottom: 30 },
        bodyStyles: {
          minCellHeight: 23,
          valign: "middle",
          halign: "center",
          fontSize: 8,
        },
        didDrawPage: (data) => {
          doc.setFontSize(7);
          doc.text(
            `Page ${data.pageNumber}`,
            doc.internal.pageSize.width - 20,
            doc.internal.pageSize.height - 20,
            { align: "right", style: "italic" }
          );
        },
      });

      doc.save(`Schedule_${dateToday}.pdf`);
    } catch (err) {
      console.error(err);
    }
  };

  const countDoneTodayPatients = (patients) => {
    return (
      patients.filter(
        (patient) =>
          patient.TreatmentDue === true ||
          patient.NeedsUrgentTreatment === true ||
          (patient?.CreatedOn &&
            moment(new Date()).format("YYYY-MM-DD") ===
              moment(patient.CreatedOn.split("T")[0]).format("YYYY-MM-DD")) ||
          (patient?.progress_notes &&
            patient.progress_notes.length > 0 &&
            patient.progress_notes[patient.progress_notes.length - 1] &&
            moment(new Date()).format("YYYY-MM-DD") ===
              moment(
                patient.progress_notes[
                  patient.progress_notes.length - 1
                ].updatedAt.split("T")[0]
              ).format("YYYY-MM-DD")) ||
          (patient?.progress_notes &&
            patient.progress_notes.length > 0 &&
            patient.progress_notes[patient.progress_notes.length - 1] &&
            moment(new Date()).format("YYYY-MM-DD") ===
              moment(
                patient.progress_notes[
                  patient.progress_notes.length - 1
                ].createdAt.split("T")[0]
              ).format("YYYY-MM-DD"))
      ).length ?? 0
    );
  };

  return (
    <div
      className="px-2 py-3 my-1"
      // style={{
      //   minHeight: "240px",
      // }}
    >
      {!hasPhysioPractice ? (
        <>
          {/* <>
            {adminRole &&
            superAdminFacilitySchedulesData &&
            superAdminFacilitySchedulesData.length > 0 ? (
              superAdminFacilitySchedulesData.map((schedFacility, index) => (
                <div
                  className={`${
                    superAdminFacilitySchedulesData.length > 1 &&
                    superAdminFacilitySchedulesData.length - 1 !== index
                      ? "border-bottom"
                      : ""
                  } px-3 pt-2 pb-3`}
                  key={index}
                >
                  <div className="d-flex">
                    <p className="orange-1" style={{ fontWeight: 500 }}>
                      Facility:
                    </p>
                    <p className="ml-2">{schedFacility.facilityName}</p>
                  </div>
                  <div className="d-flex">
                    <p className="orange-1" style={{ fontWeight: 500 }}>
                      <u>Services</u>
                    </p>
                  </div>
                  <div className="row d-flex justify-content-between">
                    <div className="d-flex col">
                      <p className="orange-1" style={{ fontWeight: 500 }}>
                        Podiatry:
                      </p>
                      <p className="ml-2">
                        {schedFacility?.podiatryServices?.podiatristName}
                      </p>
                    </div>
                    <div className="d-flex mr-5 col justify-content-end">
                      <p
                        className="orange-1 download-btn"
                        style={{ fontWeight: 500, cursor: "pointer" }}
                        onClick={async () =>
                          await downloadScheduleTable(
                            schedFacility.facilityId,
                            "podiatry"
                          )
                        }
                      >
                        Download Schedules
                      </p>
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between">
                    <div className="d-flex col">
                      <p className="orange-1" style={{ fontWeight: 500 }}>
                        Dietetics:
                      </p>
                      <p className="ml-2">
                        {schedFacility?.dieteticsServices?.dietitianName}
                      </p>
                    </div>
                    <div className="d-flex mr-5 col justify-content-end">
                      <p
                        className="orange-1 download-btn"
                        style={{ fontWeight: 500, cursor: "pointer" }}
                        onClick={async () =>
                          await downloadScheduleTable(
                            schedFacility.facilityId,
                            "dietetics"
                          )
                        }
                      >
                        Download Schedules
                      </p>
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between">
                    <div className="d-flex col">
                      <p className="orange-1" style={{ fontWeight: 500 }}>
                        Physiotherapy:
                      </p>
                      <p className="ml-2">
                        {schedFacility?.physioServies?.physioName}
                      </p>
                    </div>
                    <div className="d-flex mr-5 col justify-content-end">
                      <p
                        className="orange-1 download-btn"
                        style={{ fontWeight: 500, cursor: "pointer" }}
                        onClick={async () =>
                          await downloadScheduleTable(
                            schedFacility.facilityId,
                            "physio"
                          )
                        }
                      >
                        Download Schedules
                      </p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <>
                <div
                  className="h-100 w-100 align-items-center d-flex text-center justify-content-center"
                  style={{
                    minHeight: "240px",
                    fontSize: "20px",
                    letterSpacing: "1px",
                    fontWeight: "bolder",
                    opacity: 0.2,
                    backgroundColor: "rgba(0, 0, 0, 0.07)",
                    borderRadius: "25px",
                  }}
                >
                  No Appointments Today
                </div>
              </>
            )}
          </> */}
          {scheduleData && scheduleData.length > 0 ? (
            scheduleData.map((data, index) => (
              <div
                className={`${
                  scheduleData.length > 1 ? "border-bottom" : ""
                } px-3 pt-4`}
                key={index}
              >
                {hasPhysioPractice ? null : (
                  <div>
                    <div className="d-flex">
                      <p className="orange-1" style={{ fontWeight: 500 }}>
                        Facility:
                      </p>
                      <p className="ml-2">{data.FacilityName}</p>
                    </div>
                    <div className="d-flex">
                      <p className="orange-1" style={{ fontWeight: 500 }}>
                        Address:
                      </p>
                      <a
                        className="ml-2"
                        rel="noreferrer"
                        href={`https://maps.google.com/?q=${data.Address}`}
                        target="_blank"
                      >
                        {data.Address}
                      </a>
                    </div>
                    <div className="d-flex">
                      <p className="orange-1" style={{ fontWeight: 500 }}>
                        Contact Number:
                      </p>
                      <p className="ml-2">{data.Phone}</p>
                    </div>
                    <div className="d-flex">
                      <p className="orange-1" style={{ fontWeight: 500 }}>
                        Period:
                      </p>
                      <p className="ml-2">{data.Period}</p>
                    </div>
                    <div className="d-flex">
                      <p className="orange-1" style={{ fontWeight: 500 }}>
                        Residents to be Seen:
                      </p>
                      <a
                        className="ml-2"
                        href={`/patients?page=1&patientId=null&tab=1&facility=${data.FacilityId}&filter=now`}
                      >
                        {countDoneTodayPatients(data.ResidentsToBeTreated)}
                      </a>
                    </div>
                    <div className="d-flex">
                      <p className="orange-1" style={{ fontWeight: 500 }}>
                        Residents Due for Assessment:
                      </p>
                      <a
                        className="ml-2"
                        href={`/patients?page=1&patientId=null&tab=1&facility=${data.FacilityId}&filter=assessmentDue`}
                      >
                        {data.ResidentsDueForAssessment}
                      </a>
                    </div>
                    <div className="d-flex">
                      <p className="orange-1" style={{ fontWeight: 500 }}>
                        Residents Due for Treatment:
                      </p>
                      <a
                        className="ml-2"
                        href={`/patients?page=1&patientId=null&tab=1&facility=${data.FacilityId}&filter=treatmentDue`}
                      >
                        {data.ResidentsDueForTreatment}
                      </a>
                    </div>
                    <div className="d-flex">
                      <p className="orange-1" style={{ fontWeight: 500 }}>
                        Urgent Residents:
                      </p>
                      <a
                        className="ml-2"
                        href={`/patients?page=1&patientId=null&tab=1&facility=${data.FacilityId}&filter=urgent`}
                      >
                        {data.UrgentTreatmentResidentCount}
                      </a>
                    </div>
                    <div className="d-flex">
                      <p className="orange-1" style={{ fontWeight: 500 }}>
                        Confirmed Bookings:
                      </p>
                      <a
                        className="ml-2"
                        href={`/patients?page=1&patientId=null&tab=1&facility=${
                          data.FacilityId
                        }&filter=confirmedBooking&date=${moment(
                          data.StartDateTime
                        ).format("YYYY-MM-DD")}`}
                      >
                        {data.ResidentsConfirmed ?? 0}
                      </a>
                    </div>
                  </div>
                )}
                <div className="text-right align-items-center py-2">
                  <p style={{ fontWeight: 500 }}>
                    <Button
                      // to={`/pdf-view-schedules/${data.FacilityId}`}
                      onClick={async () => await downloadTable(data.FacilityId)}
                      className="landing-link orange-1 mx-2 text-white"
                    >
                      Download Schedules
                    </Button>
                    <NavLink
                      to={`/patients?page=1&patientId=null&tab=1&facility=${data.FacilityId}&filter=today`}
                      className="landing-link orange-1 mx-2"
                    >
                      View Residents
                    </NavLink>
                  </p>
                </div>
              </div>
            ))
          ) : (
            <>
              <div
                className="h-100 w-100 align-items-center d-flex text-center justify-content-center"
                style={{
                  minHeight: "240px",
                  fontSize: "20px",
                  letterSpacing: "1px",
                  fontWeight: "bolder",
                  opacity: 0.2,
                  backgroundColor: "rgba(0, 0, 0, 0.07)",
                  borderRadius: "25px",
                }}
              >
                No Appointments Today
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div>
            <div className="text-right align-items-center py-2">
              <p style={{ fontWeight: 500 }}>
                <NavLink
                  to={`/patients?page=1&patientId=null&tab=2&facility=null&filter=all`}
                >
                  <Button className="landing-link orange-1 mx-2 text-white">
                    Facilities
                  </Button>
                </NavLink>
                <NavLink
                  to={`/patients?page=1&patientId=null&tab=2&facility=null&filter=all`}
                  className="landing-link orange-1 mx-2"
                >
                  View Patients List
                </NavLink>
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DashboardSummary;
