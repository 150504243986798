import React from "react";

const Contact = () => {
  return (
    <div className="container-fluid">
      <div className="py-4">
        <h1>Contact Page</h1>
      </div>
    </div>
  );
};

export default Contact;
