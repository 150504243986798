import {
  GET_ALL_USERS_LOVED1S,
} from "../constants/actionsTypes";

const loved1s = (lovedOnes = [], action) => {
  switch (action.type) {
    case GET_ALL_USERS_LOVED1S:
      return action.lovedOnes;
    default:
      return lovedOnes;
  }
};

export default loved1s;
