//general packages
import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";

//main
import AppWithRouterAccess from "./AppWithRouterAccess";

// Font Awesome Icons
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Loader from "./components/reusables/Loader";
library.add(fab, far, fas);
function App() {
	return (
		<Suspense fallback={<Loader />}>
			<Router>
				<AppWithRouterAccess />
			</Router>
		</Suspense>
	);
}

export default App;
