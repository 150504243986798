import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";
import * as api from "../../../api";
import moment from "moment";
import Swal from "sweetalert2";
// import ModernDatepicker from "react-modern-datepicker";

const Tasks = () => {
	// const { authState, oktaAuth } = useOktaAuth();
	// const userId = authState.accessToken.claims.user?._id;
	const history = useHistory();
	const authState = JSON.parse(localStorage.getItem("auth0-token-storage"));
	const userClaims = JSON.parse(localStorage.getItem("user-claims"));
	const accessToken = authState?.access_token;
	const userId = userClaims?._id;
	const [allMyTasks, setAllMyTasks] = useState([]);

	const [showTask, setShowTask] = useState(false);
	const [loader, setLoader] = useState(false);
	const [myTasks, setMyTasks] = useState(null);
	const [updateTask, setUpdateTask] = useState({
		assignedTo: [`${userId}`],
		subject: "",
		description: "",
		startDate: "",
		endDate: "",
	});

  const [tasksDate, setTasksDate] = useState({
    startDate: "",
    endDate: "",
  });

  const handleClose = () => {
    setShowTask(false);
    setMyTasks(null);
  };

	const handleOpenTask = (task) => {
		setMyTasks(task);
		setShowTask(true);
	};

	const handleOnChangeTask = (e) => {
		const newObject = { ...updateTask, [e.target.name]: e.target.value };
		setUpdateTask(newObject);
	};

  const onChangeDate = (date, name) => {
    setTasksDate({
      ...tasksDate,
      [name]: date,
    });
  };

  useEffect(() => {
    if (tasksDate) {
      const { startDate, endDate } = tasksDate;

      setUpdateTask((prevState) => ({
        ...prevState,
        startDate: startDate
          ? moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD")
          : prevState.startDate,
        endDate: endDate
          ? moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD")
          : prevState.endDate,
      }));
    }
  }, [tasksDate]);

  useEffect(() => {
    if (tasksDate) {
      const { startDate, endDate } = tasksDate;

      setUpdateTask((prevState) => ({
        ...prevState,
        startDate: startDate
          ? moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD")
          : prevState.startDate,
        endDate: endDate
          ? moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD")
          : prevState.endDate,
      }));
    }
  }, [tasksDate]);

  const handleExpiration = () => {
    localStorage.removeItem("auth0-token-storage");
    localStorage.removeItem("user-claims");
    history.push("/login");
  };

  useEffect(() => {
    const newObj = {
      ...updateTask,
      subject: myTasks?.subject,
      description: myTasks?.description,
      // startDate: myTasks && moment(myTasks.startDate).format("YYYY-MM-DD"),
      // endDate: myTasks && moment(myTasks.endDate).format("YYYY-MM-DD"),
    };
    setUpdateTask(newObj);
    setTasksDate({
      ...tasksDate,
      startDate: myTasks && moment(myTasks.startDate).format("DD/MM/YYYY"),
      endDate: myTasks && moment(myTasks.endDate).format("DD/MM/YYYY"),
    });
  }, [myTasks]);

  useEffect(() => {
    const getMyTasks = async () => {
      setLoader(true);
      try {
        const data = await api.getTasks(accessToken);
        setAllMyTasks(data.data.data);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        if (
          error?.response?.status === 401 &&
          error?.response?.data?.errors?.length > 0 &&
          error?.response?.data?.errors[0]?.message === "Invalid Token"
        ) {
          handleExpiration();
        }
      }
    };

		getMyTasks();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accessToken]);

  const updateMyTask = async () => {
    setLoader(true);
    try {
      const data = await api.updateTask(updateTask, myTasks._id, accessToken);
      let updatedTask = allMyTasks.map((task) => {
        if (task._id === myTasks._id) {
          return data.data.data;
        }
        return task;
      });
      setAllMyTasks(updatedTask);
      Swal.fire({
        html: "Successfully updated the task!",
        icon: "success",
        title: "Success",
        confirmButtonColor: "#833f84",
      });
      setShowTask(false);
      setLoader(false);
    } catch (err) {
      console.error(err);
      Swal.fire({
        title: "Error!",
        html: err.response.data.errors[0].message,
        icon: "info",
        confirmButtonColor: "#833f84",
      });
      setLoader(false);
    }
  };

  const doneTask = async () => {
    setLoader(true);
    try {
      const data = await api.doneTheTask(myTasks._id, updateTask, accessToken);
      let updatedTask = allMyTasks.map((task) => {
        if (task._id === myTasks._id) {
          return data.data.data;
        }
        return task;
      });
      setAllMyTasks(updatedTask);
      Swal.fire({
        html: "Successfully completed task!",
        icon: "success",
        title: "Success",
        confirmButtonColor: "#833f84",
      });
      setShowTask(false);
      setLoader(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  const deferrTask = async () => {
    setLoader(true);
    try {
      const data = await api.deferredTheTask(
        myTasks._id,
        updateTask,
        accessToken
      );
      let updatedTask = allMyTasks.map((task) => {
        if (task._id === myTasks._id) {
          return data.data.data;
        }
        return task;
      });
      setAllMyTasks(updatedTask);
      Swal.fire({
        html: "Successfully deferred task!",
        icon: "success",
        title: "Success",
        confirmButtonColor: "#833f84",
      });
      setShowTask(false);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      if (
        error?.response?.status === 401 &&
        error?.response?.data?.errors?.length > 0 &&
        error?.response?.data?.errors[0]?.message === "Invalid Token"
      ) {
        handleExpiration();
      }
    }
  };

  return !accessToken ? (
    handleExpiration()
  ) : (
    <>
      <div className="container pb-0 mb-2">
        <div
          className="orange-bg-1 text-light py-3 my-0 d-flex justify-content-between align-items-center align-content-center align-self-center"
          style={{
            borderTopLeftRadius: "25px",
            borderTopRightRadius: "25px",
          }}
        >
          <div className="d-flex justify-content-center align-items-center m-auto align-content-center align-self-center">
            <FontAwesomeIcon
              icon={["fa", "clipboard-list"]}
              className="mr-2 "
            />
            <h5 className="text-light align-items-center justify-content-center d-flex m-auto">
              Tasks
            </h5>
          </div>
        </div>
        <div
          className="p-0 bg-white border"
          style={{
            borderBottomLeftRadius: "25px",
            borderBottomRightRadius: "25px",
            backgroundColor: "white",
          }}
        >
          <div
            className="mx-0 px-2 py-3"
            style={{
              minHeight: "240px",
            }}
          >
            <div className="">
              <div className="mx-2">
                {!loader && allMyTasks.length > 0 ? (
                  <>
                    <div className="d-flex justify-content-between align-items-center px-3 py-2 orange-1 font-weight-bold">
                      <h6>Subject</h6>
                      <h6>Status</h6>
                    </div>
                    {allMyTasks &&
                      allMyTasks.map((task, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              display: task.status !== "pending" ? "none" : "",
                            }}
                          >
                            <div
                              className="p-3 d-flex justify-content-between align-items-center border mb-1"
                              onClick={() => handleOpenTask(task)}
                              style={{ cursor: "pointer" }}
                              data-target={`#task${task._id}`}
                            >
                              <p>
                                {task.subject.charAt(0).toUpperCase() +
                                  task.subject.slice(1).toLowerCase()}
                              </p>
                              <p>
                                {task.status === "done"
                                  ? "Completed"
                                  : task.status === "deferred"
                                  ? "Deferred"
                                  : "Active"}
                                {/* {task.status.charAt(0).toUpperCase() +
                                  task.status.slice(1).toLowerCase()} */}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                  </>
                ) : (
                  <>
                    <div
                      className="h-100 w-100 align-items-center d-flex text-center justify-content-center my-auto"
                      style={{
                        fontSize: "15px",
                        letterSpacing: "1px",
                        fontWeight: "bolder",
                        opacity: 0.2,
                        minHeight: "240px",
                        backgroundColor: "rgba(0, 0, 0, 0.07)",
                        borderRadius: "25px",
                      }}
                    >
                      No Tasks Available
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="md"
        show={showTask}
        onHide={() => handleClose()}
        aria-labelledby="example-modal-sizes-title-lg"
        // centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="orange-1">Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="form-group w-75">
              <label>Subject</label>
              <input
                type="text"
                name="subject"
                className="form-control"
                placeholder="Subject"
                value={updateTask?.subject ?? ""}
                onChange={handleOnChangeTask}
              />
            </div>
            <div className="form-group">
              <label>Description</label>
              <textarea
                className="form-control"
                name="description"
                rows="5"
                placeholder="Description"
                value={updateTask?.description ?? ""}
                onChange={handleOnChangeTask}
              ></textarea>
            </div>
            <div className="form-group w-50">
              <label>Start Date</label>
              <input
                type="date"
                className="form-control"
                name="startDate"
                value={updateTask?.startDate ?? null}
                onChange={handleOnChangeTask}
              />
              {/* <div style={{ border: "1px solid #ced4da" }}>
                <ModernDatepicker
                  className="form-control"
                  format="DD/MM/YYYY"
                  name="startDate"
                  date={tasksDate.startDate}
                  onChange={(date) => onChangeDate(date, "startDate")}
                  primaryColor="#f36917"
                  placeholder="dd/mm/yyyy"
                />
              </div> */}
            </div>
            <div className="form-group w-50">
              <label>End Date</label>
              <input
                type="date"
                className="form-control"
                name="endDate"
                value={updateTask?.endDate ?? null}
                onChange={handleOnChangeTask}
              />
              {/* <div style={{ border: "1px solid #ced4da" }}>
                <ModernDatepicker
                  className="form-control"
                  format="DD/MM/YYYY"
                  name="endDate"
                  date={tasksDate.endDate}
                  onChange={(date) => onChangeDate(date, "endDate")}
                  primaryColor="#f36917"
                  placeholder="dd/mm/yyyy"
                />
              </div> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={updateMyTask}>
            Update Task
          </button>
          <button className="btn btn-primary" onClick={doneTask}>
            Completed Task
          </button>
          <button className="btn btn-dark" onClick={deferrTask}>
            Deferred Task
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Tasks;
