import React from "react";
import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TicketSuccess = () => {
  return (
    <div className="container">
      <div className="support-success-bg">
        <div
          className="support-ticket my-5 px-3 align-items-center vertical-center justify-content-center text-center"
          style={{ width: "100%", height: "35rem" }}
        >
          <div>
            <FontAwesomeIcon
              icon={["fas", "check"]}
              className="text-white mb-3 orange-bg-1 p-2"
              style={{ fontSize: "60px", borderRadius: "50px" }}
            />
            <h2 className="orange-1 mb-3 font-weight-bold">TICKET CREATED</h2>
            <p className="ticket mb-0">
              Ticket has been created successfully.{" "}
            </p>
            <p className="ticket">Your Ticket Number is HAPI012345</p>
            <div className="align-bottom">
              <NavLink to="/support">
                <p
                  className="orange-1 font-weight-bold"
                  style={{ fontSize: "1.2rem" }}
                >
                  Go Back
                </p>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketSuccess;
