export const baseURL = process.env.REACT_APP_NODE_URL;

//patients actions
export const FETCH_ALL = "FETCH_ALL";
export const FETCH = "FETCH";
export const CREATE = "CREATE";
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";

//roles actions
export const FETCH_ROLES = "FETCH_ROLES";

//facilities actions
export const FETCH_FACILITIES = "FETCH_FACILITIES";
export const CREATE_FACILITY = "CREATE_FACILITY";
export const UPDATE_FACILITY = "UPDATE_FACILITY";
export const DELETE_FACILITY = "DELETE_FACILITY";

//uusers actions
export const CREATE_USER = "CREATE_USER";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";

//loved1s actions
export const GET_ALL_USERS_LOVED1S = "GET_ALL_USERS_LOVED1S";
export const UPDATE_USERS_LOVED1S = "UPDATE_USERS_LOVED1S";
