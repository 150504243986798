(function (self) {
    if (self.URLSearchParams) {
        return;
    }

    var URLSearchParams = function (searchString) {
        Object.defineProperty(this, '_entries', { writable: true, value: {} });
        if (typeof searchString === 'string') {
            if (searchString !== '') {
                searchString = searchString.replace(/^\?/, '');
                var attributes = searchString.split('&');
                var attribute;
                for (var i = 0; i < attributes.length; i++) {
                    attribute = attributes[i].split('=');
                    this.append(
                        decodeURIComponent(attribute[0]),
                        (attribute.length > 1) ? decodeURIComponent(attribute[1]) : ''
                    );
                }
            }
        }
    };

    URLSearchParams.prototype.append = function (name, value) {
        if (name in this._entries) {
            this._entries[name].push(String(value));
        } else {
            this._entries[name] = [String(value)];
        }
    };

    URLSearchParams.prototype.toString = function () {
        var searchString = '';
        for (var name in this._entries) {
            var value = this._entries[name];
            for (var i = 0; i < value.length; i++) {
                searchString += (searchString ? '&' : '') + encodeURIComponent(name) + '=' + encodeURIComponent(value[i]);
            }
        }
        return searchString;
    };

    self.URLSearchParams = URLSearchParams;
})(typeof global !== 'undefined' ? global : (typeof window !== 'undefined' ? window : this));
