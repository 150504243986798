/* eslint-disable react-hooks/exhaustive-deps */
//import packages
import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast, Toaster } from "react-hot-toast";
// Push Notification
import { db } from "../../firebase/firebase";
import { ref, onValue, off } from "firebase/database";

import * as api from "../../api";
import moment from "moment";
import userRoles from "../../role";

//import assets
import logo from "../../assets/images/hapi-partner-logo.png";

//import other components
// import SearchBar from "../searchbar/searchbar";
// import NavInfo from "../nav-info/info";
import ClockIn from "../pages/landing_page/ClockIn";
import ClockOut from "../pages/landing_page/ClockOut";
import Loader from "../reusables/Loader";
import ResidentDetails from "../pages/notifications/ResidentDetails";

function Navbar({
  searchTerm,
  setSearchTerm,
  setSort,
  changeURLPatient,
  confirmShowed,
  setConfirmShowed,
  saved,
  setSaved,
  currentPage,
  setCurrentPage,
  setNavBarLoader,
  selectedOption,
  notificationData,
  setNotificationData,
  notificationLoader,
  unreadCount,
  setUnreadCount,
}) {
  // const { authState, oktaAuth } = useOktaAuth();
  const authState = JSON.parse(localStorage.getItem("auth0-token-storage"));
  const userClaims = JSON.parse(localStorage.getItem("user-claims"));
  const accessToken = authState?.access_token;
  const userId = userClaims?._id ? userClaims?._id : null;
  const { facilityManagerRole, careHomeManagerRole } = userRoles();
  const history = useHistory();
  const location = useLocation();
  // For Clock in and Out Button
  const [clockInData, setClockInData] = useState(null);
  const [showClock, setShowClock] = useState(false);
  const [clockInToggle, setClockInToggle] = useState(false);
  const [getClockInStatusLoader, setGetClockInStatusLoader] = useState(false);
  let param =
    location?.search?.split("&")?.filter((item) => item !== "") ?? null;
  const [paramState] = useState({
    facility:
      param[3]?.split("=")[1] === "null" || !param[3]?.split("=")[1]
        ? null
        : param[3]?.split("=")[1].split(",").length > 1
        ? param[3]
            ?.split("=")[1]
            ?.split(",")
            ?.map((i) => parseInt(i))
        : [parseInt(param[3].split("=")[1])],
    filter:
      param[4]?.split("=")[1] === "all" || !param[4]?.split("=")[1]
        ? "all"
        : param[4].split("=")[1],
  });
  const [showPopup, setShowPopup] = useState(false);
  let notifRef = useRef();

  const handleBellClick = () => {
    setUnreadCount(false);
    setShowPopup(!showPopup);
  };

  useEffect(() => {
    let handler = (e) => {
      if (notifRef.current && !notifRef.current.contains(e.target)) {
        setShowPopup(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  useEffect(() => {
    async function data() {
      if (!authState) {
        // When user isn't authenticated, forget any user info
        // setUserInfo(null);
        setShowClock(false);
      } else {
        // oktaAuth.getUser().then((info) => {
        //   setUserInfo(info);
        setShowClock(true);
        // });
      }
    }
    data();
  }, [authState]); // Update if authState changes

  const handleExpiration = () => {
    localStorage.removeItem("auth0-token-storage");
    localStorage.removeItem("user-claims");
    history.push("/login");
  };

  const clockInStatus = async () => {
    if (!authState || !authState.access_token) return;

    setGetClockInStatusLoader(true);
    try {
      const data = await api.getClockInStatus(accessToken);

      if (
        data?.data?.data &&
        data?.data?.data[data?.data?.data?.length - 1]?.Description ===
          "Clock In"
      ) {
        setClockInData(data.data.data[0]);
      } else {
        setClockInData(null);
      }
    } catch (error) {
      // history.push("/dashboard");
      console.log("error getting clock in status", error);
      if (
        error?.response?.status === 401 &&
        error?.response?.data?.errors?.length > 0 &&
        error?.response?.data?.errors[0]?.message === "Invalid Token"
      ) {
        handleExpiration();
      }
    } finally {
      setGetClockInStatusLoader(false);
    }
  };

  useEffect(() => {
    clockInStatus();
  }, [accessToken, clockInToggle]);

  const handleNotifClick = (residentId) => {
    history.push(`/allConsents/${residentId}`);
    setShowPopup(false);
  };

  const handlePushNotifications = () => {
    let isMounted = false;
    const notifRef = ref(db, `${userId}/notif`);
    const onDataChange = (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const innerObjectKey = Object.keys(data).find((key) => data[key]._id);
        const array = innerObjectKey ? data[innerObjectKey] : null;
        // console.log(array);
        if (array && array !== null) {
          if (isMounted) {
            setNotificationData((prevArray) => [array, ...prevArray]);
            setUnreadCount(true);
            toast(array.notes + " a " + array.event);
          } else {
            isMounted = true;
          }
        }
      }
    };
    onValue(notifRef, onDataChange);
    return () => {
      isMounted = false;
      off(notifRef, onDataChange);
    };
  };

  useEffect(() => {
    handlePushNotifications();
  }, []);

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <div className="container-fluid hapi-header shadow">
        <div className="row hapi-header-items py-3 d-flex justify-content-between flex-column flex-lg-row position-relative">
          <div className="col-lg-2 col-12 d-flex justify-content-center mr-1">
            <NavLink
              to={`${
                location.pathname.indexOf("/login") >= 0
                  ? "/login"
                  : "/landing-page"
              }`}
            >
              <img
                src={logo}
                className="img-fluid pt-2"
                alt="logo"
                style={{ height: "6rem" }}
              />
            </NavLink>
          </div>
          <div className="d-lg-flex col-12 col-lg-6 justify-content-start align-items-center mx-1 mx-lg-1">
            {location.pathname.indexOf("/patients") >= 0 ? (
              <>
                {location.pathname.indexOf("/patients/add") >= 0 ||
                location.pathname.indexOf("/patients/edit") >= 0 ? null : (
                  <div className="justify-content-center justify-content-lg-start col-12 col-lg-6">
                    {selectedOption.length <= 0 ? null : (
                      <input
                        type="text"
                        placeholder="Search Patients..."
                        className="form-control my-4 py-1 w-100 w-md-50"
                        style={{ width: "40%" }}
                        value={searchTerm}
                        onChange={(e) => {
                          setNavBarLoader(true);
                          setSearchTerm(e.target.value);
                          setCurrentPage(1);
                          changeURLPatient(
                            null,
                            1,
                            null,
                            paramState.facility,
                            paramState.filter
                          );
                          setNavBarLoader(false);
                        }}
                      />
                    )}
                  </div>
                )}
                {location.pathname.indexOf("/patients/add") >= 0 ||
                location.pathname.indexOf("/patients/edit") >= 0 ? null : (
                  <div className="d-flex justify-content-center justify-content-lg-start col-12 col-lg-6 my-auto">
                    <div className="form-group form-row my-auto">
                      <h6 className="my-auto mr-3">SORT BY: </h6>
                      <select
                        className="form-control form-control-sm d-inline filter-dropdown"
                        style={{ width: "auto" }}
                        id="patientListFilterName"
                        name="patientListFilterName"
                        onChange={(e) => {
                          setSort(e.target.value);
                          changeURLPatient(
                            null,
                            1,
                            null,
                            paramState.facility,
                            paramState.filter
                          );
                        }}
                      >
                        <option value=""></option>
                        <option value="FirstName">First name ascending</option>
                        <option value="FirstName_desc">
                          First name descending
                        </option>
                        <option value="LastName">Last name ascending</option>
                        <option value="LastName_desc">
                          Last name descending
                        </option>
                        <option value="LastTreatmentDate">
                          Last treatment ascending
                        </option>
                        <option value="LastTreatmentDate_desc">
                          Last treatment descending
                        </option>
                        <option value="Ward">Ward name ascending</option>
                        <option value="Ward_desc">Ward name descending</option>
                      </select>
                    </div>
                  </div>
                )}
              </>
            ) : null}
          </div>
          {facilityManagerRole || careHomeManagerRole ? null : (
            <div
              className={
                showClock
                  ? "col-lg-2 col-12 d-lg-flex justify-content-center align-items-center"
                  : "d-none justify-content-center"
              }
            >
              {!clockInData ? (
                <div className="clock-out-container justify-content-center align-items-center ml-lg-4">
                  <ClockIn
                    setClockIn={setClockInToggle}
                    getClockInStatusLoader={getClockInStatusLoader}
                    // userFacilities={userFacilities}
                  />
                </div>
              ) : (
                <div className="clock-in-container justify-content-center align-items-center w-100 ml-lg-4">
                  <ClockOut
                    clockInData={clockInData}
                    setClockIn={setClockInToggle}
                    getClockInStatusLoader={getClockInStatusLoader}
                    // clockIn={clockIn}
                    // setClockIn={setClockIn}
                    // userFacilities={userFacilities}
                  />
                </div>
              )}
            </div>
          )}
          <div className="col-12 col-lg-1 d-flex my-auto justify-content-end justify-content-lg-start align-items-center">
            {location.pathname.includes("/login") ||
            location.pathname.includes("/forgotPassword") ||
            location.pathname.includes("/sign-up") ||
            location.pathname.includes(
              "/consent"
            ) ? null : !authState ? null : (
              <div className="position-relative d-inline-block" ref={notifRef}>
                <span
                  className="bell-icon"
                  onClick={handleBellClick}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    icon={["fa", "bell"]}
                    className="fa-lg mx-1 orange-1"
                    style={{ cursor: "pointer" }}
                  />
                </span>
                {/* {unreadCount > 0 && (
                  <span
                    className="badge"
                    style={{
                      position: "absolute",
                      top: "-8px",
                      right: "-8px",
                      backgroundColor: "red",
                      color: "white",
                      borderRadius: "50%",
                      padding: "4px",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    {unreadCount > 5 ? "5+" : unreadCount}
                  </span>
                )} */}
                {unreadCount && (
                  <span
                    style={{
                      position: "absolute",
                      display: "inline-block",
                      height: "7px",
                      width: "7px",
                      right: "2px",
                      backgroundColor: "red",
                      borderRadius: "50%",
                      verticalAlign: "text-top",
                    }}
                  ></span>
                )}

                {showPopup && (
                  <div
                    className="position-absolute notificationPopup"
                    style={{
                      // position: "fixed",
                      top: "38px",
                      right: "20px",
                      width: "300px",
                      height: "380px",
                      backgroundColor: "white",
                      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                      zIndex: 9999,
                      border: "2px solid #f36917",
                      borderRadius: "10px",
                      overflowY: "auto",
                    }}
                  >
                    {!notificationLoader ? (
                      <>
                        <div
                          className="orange-bg-1 px-2 py-2 text-white sticky-top"
                          style={{
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                        >
                          <h6 className="my-auto">Notifications</h6>
                        </div>
                        {notificationData && notificationData.length > 0 ? (
                          <>
                            {notificationData.slice(0, 5).map((data, index) => (
                              <div
                                key={index}
                                className="d-flex border-bottom py-3 pr-2 notifArray"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleNotifClick(data.resident)}
                              >
                                <div className="col-12 dashboardNotif">
                                  <p
                                    className="orange-1"
                                    style={{ fontSize: "0.8rem" }}
                                  >
                                    <strong>
                                      <ResidentDetails
                                        residentId={data.resident}
                                      />
                                    </strong>
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "0.8rem",
                                      color: "#545554",
                                    }}
                                  >
                                    {data.notes}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "0.75rem",
                                      color: "#545554",
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={[
                                        "fa",
                                        `${
                                          data.notes.includes("Signed") ||
                                          data.notes.includes("signed")
                                            ? "edit"
                                            : "plus-circle"
                                        }`,
                                      ]}
                                      className="mr-1 orange-1"
                                    />{" "}
                                    {moment(new Date(data.createdAt)).fromNow()}
                                  </p>
                                </div>
                                {/* <div className="col-1 my-auto">
                            <p className="text-center">
                              <svg
                                fill="#f36917"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 384 512"
                                style={{
                                  height: "10px",
                                  width: "10px",
                                  cursor: "pointer",
                                }}
                              >
                                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                              </svg>
                            </p>
                            <p>
                              <label className="overview-input2 mx-auto">
                                <input
                                  className=""
                                  type="radio"
                                  style={{
                                    height: "10px",
                                    width: "10px",
                                    cursor: "pointer",
                                  }}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </p>
                          </div> */}
                              </div>
                            ))}
                          </>
                        ) : (
                          <div
                            className="h-100 w-100 align-items-center d-flex text-center justify-content-center my-auto"
                            style={{
                              fontSize: "15px",
                              letterSpacing: "1px",
                              fontWeight: "bolder",
                              opacity: 0.2,
                              minHeight: "240px",
                              backgroundColor: "rgba(0, 0, 0, 0.07)",
                              borderRadius: "25px",
                            }}
                          >
                            No Notifications Available
                          </div>
                        )}
                        {notificationData.length > 4 && (
                          <div>
                            <NavLink
                              to="/notifications?page=1"
                              onClick={() => setShowPopup(false)}
                            >
                              <p
                                className="text-center orange-1 see-all"
                                style={{
                                  cursor: "pointer",
                                  fontSize: "0.8rem",
                                }}
                              >
                                See all
                              </p>
                            </NavLink>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="my-3">
                        <Loader />
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
