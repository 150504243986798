import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./Users.css";

import { Link, Redirect } from "react-router-dom";

import Pagination from "../../reusables/Paginator/Pagination";

import * as api from "../../../api";

import Loader from "../../reusables/Loader";

let PageSize = 20;

const Users = () => {
  // const { authState } = useOktaAuth();
  // const accessToken = authState.accessToken;
  const authState = JSON.parse(localStorage.getItem("auth0-token-storage"));
  const accessToken = authState?.access_token;
  const history = useHistory();
  const [loader, setLoader] = useState(false);

  const [users, setUsers] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [allFacilites, setAllFacilites] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTableData, setCurrentTableData] = useState([]);

  const getUserRoles = (roleIds) => {
    let userRoles = [];
    allRoles.forEach((role) => {
      roleIds.forEach((userRole) => {
        if (role._id === userRole) {
          userRoles.push(role.name);
        }
      });
    });

    // console.log("asdfwegasvwsed roles", userRoles);
    return <p>{userRoles.toString()}</p>;
  };

  const getUserFacilities = (facilityIds) => {
    let userFacilities = [];
    allFacilites.forEach((facility) => {
      facilityIds.forEach((userFacility) => {
        if (facility.facilityId === Number(userFacility)) {
          userFacilities.push(facility.facilityShortName);
        }
      });
    });

    return <p>{userFacilities.toString()}</p>;
  };

  // const deleteUser = async (id) => {
  // 	console.log(`user with id ${id} deleted`);
  // };

  useEffect(() => {
    const getAllRoles = async () => {
      try {
        setLoader(true);
        const { data } = await api.fetchRoles();
        console.log("all roles", data);
        setAllRoles(data);
      } catch (error) {
        console.log("error getting roles", error);
        setLoader(false);
        if (
          error?.response?.status === 401 &&
          error?.response?.data?.errors?.length > 0 &&
          error?.response?.data?.errors[0]?.message === "Invalid Token"
        ) {
          handleExpiration();
        }
      }
    };

    const getAllFacilities = async () => {
      try {
        setLoader(true);
        const data = await api.fetchFacilities(accessToken);

        const facilities = data.data.data.data;
        setAllFacilites(facilities);
      } catch (error) {
        console.log("error getting facilites", error);
        if (
          error?.response?.status === 401 &&
          error?.response?.data?.errors?.length > 0 &&
          error?.response?.data?.errors[0]?.message === "Invalid Token"
        ) {
          handleExpiration();
        }
      }
    };

    const getAllUsers = async () => {
      try {
        setLoader(true);
        const { data } = await api.getAllUsers(accessToken);
        setUsers(data);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        console.log("error getting users", error);
        if (
          error?.response?.status === 401 &&
          error?.response?.data?.errors?.length > 0 &&
          error?.response?.data?.errors[0]?.message === "Invalid Token"
        ) {
          handleExpiration();
        }
      }
    };
    getAllRoles();
    getAllFacilities();
    getAllUsers();
  }, [authState, accessToken]);

  useEffect(() => {
    const getCurrentTableData = () => {
      const firstPageIndex = (currentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      const newData = users.slice(firstPageIndex, lastPageIndex);
      setCurrentTableData(newData);
    };
    getCurrentTableData();
  }, [currentPage, loader, users]);

  const handleExpiration = () => {
		localStorage.removeItem("auth0-token-storage");
		localStorage.removeItem("user-claims");
		history.push("/login");
	};

  return authState === null ||
    authState === undefined ||
    authState?.accessToken === undefined ||
    authState?.accessToken === null ? (
    <Redirect to="/login" />
  ) : (
    <div className="table-resoponive mt-4 mx-3">
      <div className="row mb-4">
        <div className="col-lg-1 my-auto text-right">
          <div className="form-group form-row my-auto">
            <small
              className="d-inline my-auto ml-2"
              htmlFor="facilitytListFilter"
            >
              SORT BY:
            </small>
          </div>
        </div>
        <div className="col-lg-3 my-auto">
          <div className="form-group form-row my-auto">
            <select
              className="form-control form-control-sm d-inline my-auto"
              id="facilityListFilter"
              name="facilitytListFilter"
            >
              <option value="male">All Users</option>
            </select>
          </div>
        </div>
      </div>
      {loader ? (
        <div className="text-center">
          <Loader />
          <p>Loading...</p>
        </div>
      ) : (
        <table className="table facilities-table table-borderless table-hover">
          <thead className="orange-bg-1 text-light">
            <tr>
              <th scope="col">First Name</th>
              <th scope="col">Middle Name</th>
              <th scope="col">Last Name</th>
              <th scope="col">Nick Name</th>
              <th scope="col">Email</th>
              <th scope="col">Roles</th>
              <th scope="col">Facilities</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-light">
            {currentTableData.map((user, index) => (
              <tr key={user._id}>
                <td>{user.firstName}</td>
                <td>{user.middleName}</td>
                <td>{user.lastName}</td>
                <td>{user.nickName}</td>
                <td>{user.email}</td>
                <td>{getUserRoles(user.roleIds)}</td>
                <td>{getUserFacilities(user.facilityIds)}</td>
                <td>
                  <Link
                    exact
                    to={`/users/edit/${user._id}`}
                    className="text-success ml-2"
                  >
                    Edit
                  </Link>
                  {/* <Link exact to="/users" onClick={() => {
                                                deleteUser(user._id);
                                            }} className="text-danger">Delete</Link> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <Pagination
        currentPage={currentPage}
        totalCount={users.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
};

export default Users;
