import React from "react";

const AlertsTop = (props) => {
  return (
    <div className={`${props.bsClass}`} role="alert" style={{ borderRadius: "12px" }}>
      {props.text}
    </div>
  );
};

export default AlertsTop;
