import React from "react";
import { NavLink } from "react-router-dom";
import "./support.style.css";

const CreateTicket = () => {
  return (
    <div className="container">
      <div className="support-create-bg">
        <form className="support-ticket my-4 pb-5">
          <h1 className="h3 fw-bold text-light px-4 py-2 mb-5">
            Create ticket
          </h1>
          <div className="col-md-11 px-4">
            <div className="form-group">
              <label for="ticket-name">Ticket Name*</label>
              <input
                type="text"
                className="form-control orange-border-2"
                id="ticket-name"
                placeholder="Create a ticket from a conversation"
                required
              />
            </div>
            <div className="form-group">
              <label for="priority">Priority</label>
              <select
                className="form-control orange-border-2 classic"
                id="priority"
              >
                <option style={{ listStyleType: "circle" }}>
                  Not Priority
                </option>
                <option selected>Medium</option>
                <option>Urgent</option>
              </select>
            </div>
            <div className="form-group">
              <label for="category">Category</label>
              <select
                className="form-control orange-border-2 classic"
                id="category"
              >
                <option>General Inquiry</option>
                <option>Re-schedule</option>
                <option>Medication</option>
              </select>
            </div>
            <div className="form-group">
              <label for="ticket-subject">Ticket Subject*</label>
              <input
                type="text"
                className="form-control orange-border-2"
                id="ticket-subject"
                placeholder="Subject (Required)"
                required
              />
            </div>
            <div className="form-group">
              <label for="ticket-desc">General Description*</label>
              <textarea
                className="form-control orange-border-2"
                id="ticket-desc"
                rows="5"
                required
              ></textarea>
            </div>
            <div className="form-group w-50">
              <label>Attachments</label>
              <input
                type="file"
                className="form-control-file font-italic mb-2"
              />
              <input type="file" className="form-control-file font-italic" />
            </div>
            <div className="form-group ml-2">
              <button type="submit" className="btn btn-primary mr-3">
                Submit Ticket
              </button>
              <button
                type="submit"
                className="btn orange-border-1 orange-1 mr-3"
              >
                Create and add another
              </button>
              {/* <NavLink to="/support"> */}
              <NavLink
                to="/support"
                type="submit"
                className="btn orange-border-1 orange-1 mr-3"
                onClick={() => {
                  window.confirm("Do you want to cancel?");
                }}
              >
                Cancel
              </NavLink>
              {/* </NavLink> */}
            </div>
          </div>
        </form>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

export default CreateTicket;
