import React, { Component } from "react";

// Font Awesome Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fab, far, fas);

class Footer extends Component {
	render() {
		return (
			<div id="footer" className="container-fluid footer-component">
				<div className="row">
					<div
						className="col-12 hapi-footer text-light py-3"
						style={{
							backgroundColor: "#595a5b",
							fontSize: "0.9rem",
							position: "fixed",
							bottom: "0",
							zIndex: 3,
						}}
					>
						<div className="row">
							<div className="col-lg-5 my-2">
								<FontAwesomeIcon icon={["far", "copyright"]} />
								<p className="ml-1 d-inline">
									Copyright 2021 Hapi Healthcare - All rights
									reserved.
								</p>
							</div>
							<div className="col-lg-7 my-2">
								<a
									href="https://www.hapihealthcare.com/about-us"
									className="footerlinks mr-2 mr-md-3"
									target="_blank"
									rel="noreferrer"
								>
									About Us
								</a>
								<a
									href="https://www.hapihealthcare.com/video-consultations"
									className="footerlinks mx-2 mx-md-3"
									target="_blank"
									rel="noreferrer"
								>
									Services
								</a>
								{/* <Link
                  to="/terms"
                  className="footerlinks mx-2 mx-md-3"
                  // target="_blank"
                  rel="noreferrer"
                >
                  Terms and Conditions
                </Link> */}
								<a
									href="https://www.hapihealthcare.com/#download-app-link"
									className="footerlinks mx-2 mx-md-3"
									target="_blank"
									rel="noreferrer"
								>
									Hapi Starts Here!
								</a>
								<a
									href="https://www.hapihealthcare.com/contact-us/uk"
									className="footerlinks ml-2 ml-md-3"
									target="_blank"
									rel="noreferrer"
								>
									Contact Us
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Footer;
