import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./landing.style.css";
import moment from "moment";
import usePracticeHook from "../../../practice";

import { appointmentData, selectFacility } from "./data";

import * as api from "../../../api";

// SVG icons
import ClientProfile from "../../../assets/svg/Landing Page/ClientProfile.svg";
import Alert from "../../../assets/svg/Landing Page/Alert.svg";
import Check from "../../../assets/svg/Landing Page/Check.svg";
import Pending from "../../../assets/svg/Landing Page/Pending.svg";
import Loader from "../../reusables/Loader";
import DashboardSummary from "./DashboardSummary";
import AdminDashboardSummary from "./AdminDashboardSummary";

import userRoles from "../../../role";
import LovedOneRequest from "./LovedOneRequest";

const Appointments = ({ scheduleData, loader, searchInDashboard }) => {
  // const [appointments, setAppointments] = useState(appointmentData);
  const [appointments, setAppointments] = useState([]);
  // const [visible, setVisible] = useState(4);
  // const { authState } = useOktaAuth();
  const authState = JSON.parse(localStorage.getItem("auth0-token-storage"));
  const accessToken = authState?.access_token;
  const { hasPhysioPractice, hasDiatecianPractice } = usePracticeHook();
  const {
    adminRole,
    otherServicesRole,
    facilityManagerRole,
    clinicalManagerRole,
    careHomeManagerRole,
  } = userRoles();
  const history = useHistory();
  const [userFacilities, setUserFacilites] = useState([]);
  const [facilityLoader, setFacilityLoader] = useState(false);
  // const [selectedFacility, setSelectedFacility] = useState("");
  const [filter, setFilter] = useState({
    week: "",
    facility: "",
  });

  const handleExpiration = () => {
    localStorage.removeItem("auth0-token-storage");
    localStorage.removeItem("user-claims");
    history.push("/login");
  };

  useEffect(() => {
    const getUserFacilities = async () => {
      setFacilityLoader(true);
      try {
        const data = await api.fetchFacilities(accessToken);
        // console.log("user facilites", data.data.data.data);
        const facilities = data.data.data.data;
        // console.log(facilities);
        const mergedFacilityInfo = [];
        // console.log("user authstate", userFacilityNames);

        facilities.forEach((facility) => {
          mergedFacilityInfo.push({
            id: facility.FacilityID,
            shortName: facility.FacilityShortName,
          });
        });
        // console.log("user facilities", mergedFacilityInfo);
        setUserFacilites(mergedFacilityInfo);
        setFacilityLoader(false);
      } catch (error) {
        setFacilityLoader(false);
        console.log("error getting user facilites", error);
        if (
          error?.response?.status === 401 &&
          error?.response?.data?.errors?.length > 0 &&
          error?.response?.data?.errors[0]?.message === "Invalid Token"
        ) {
          handleExpiration();
        }
      }
    };

    getUserFacilities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  // const filterFacility = (filteredData) => {
  //   if (
  //     filter.facility === "" ||
  //     filter.facility === null ||
  //     filter.facility === undefined
  //   ) {
  //     const filteredAppointments = filteredData.filter(
  //       (resident) => parseInt(resident.facilityId) === 380
  //     );
  //     return filteredAppointments;
  //   }

  //   const filteredAppointments = filteredData.filter(
  //     (resident) => parseInt(resident.facilityId) === parseInt(filter.facility)
  //   );

  //   return filteredAppointments;
  // };

  // const handleFacilityChange = (e) => {
  //   setFilter({ ...filter, facility: e.target.value });
  // };

  // useEffect(() => {
  //   // const filteredAppointment = filterFacility(appointmentData);
  //   scheduleData = scheduleData.sort((a, b) => {
  //     if (a.lastName < b.lastName) {
  //       return -1;
  //     }
  //     if (a.lastName > b.lastName) {
  //       return 1;
  //     }
  //     return 0;
  //   });
  //   const filteredAppointment = filterFacility(scheduleData);
  //   setAppointments(filteredAppointment);
  // }, [filter, scheduleData]);

  // Filtering Schedules Data and sorted ascending order
  // const filterBy = (data, filterKey) =>
  //   filterKey
  //     ? scheduleData.filter((item) => item.name.includes(filterKey))
  //     : data;
  // const sortBy = (data, sortKey) =>
  //   sortKey ? [...data].sort((a, b) => a[sortKey] > b[sortKey]) : data;

  // const sortAscending = (a, b) => {
  //   var c = new Date(a.start);
  //   var d = new Date(b.start);
  //   return c - d;
  // };

  // const filterByDate = scheduleData?.filter((d) => {
  //   const date = moment(new Date(d.start)).format("MM-DD-YYYY");
  //   return date === moment(new Date()).format("MM-DD-YYYY");
  // });

  // const filterByDateAndSortAscending = sortBy(
  //   filterBy(filterByDate),
  //   sortAscending
  // );

  return (
    <>
      {/* {adminRole || facilityManagerRole || clinicalManagerRole || careHomeManagerRole ? (
        <div className="container mb-3">
          <div className="row">
            <div className="col-md-12">
              <div
                className="orange-bg-1 pt-3"
                style={{
                  borderTopLeftRadius: "25px",
                  borderTopRightRadius: "25px",
                }}
              >
                <div className="d-flex justify-content-between">
                  <h5 className="ml-3 text-light" style={{ fontWeight: "500" }}>
                    Today's Facility Schedules
                  </h5>
                </div>
                <div className="col-md-8">
                  <label className="landing-selectors mt-2 w-50"></label>
                </div>
              </div>
            </div>
          </div>
          <div
            className="container border"
            style={{
              borderBottomLeftRadius: "25px",
              borderBottomRightRadius: "25px",
              backgroundColor: "white",
              //   minHeight: "240px",
            }}
          >
            {loader ? (
              <div
                className="h-100 w-100 align-items-center d-flex my-3"
                style={{
                  minHeight: "240px",
                }}
              >
                <Loader />
              </div>
            ) : (
              <>
                <div>
                  <AdminDashboardSummary scheduleData={scheduleData} />
                </div>
              </>
            )}
          </div>
        </div>
      ) : null} */}

      <div className="container mb-3">
        <div className="row">
          <div className="col-md-12">
            <div
              className="orange-bg-1 py-3"
              style={{
                borderTopLeftRadius: "25px",
                borderTopRightRadius: "25px",
              }}
            >
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="ml-3 text-light" style={{ fontWeight: "500" }}>
                  {/* Appointments */}
                  Today's Appointment
                  {/* {hasPhysioPractice
                    ? "Today's Physiotherapy Appointment"
                    : hasDiatecianPractice
                    ? "Today's Dietitian Appointments"
                    : otherServicesRole
                    ? "Today's Other Services Schedules"
                    : "Today's Podiatry Appointment"} */}
                </h5>
              </div>
              {/* <div className="col-md-8">
                <label className="landing-selectors mt-2 w-50">
                  {!facilityLoader ? (
                    <select
                      className="custom-select-sm"
                      name="facility"
                      value={filter.facility}
                      onChange={handleFacilityChange}
                    >
                      <option value="" disabled>
                        SELECT FACILITY
                      </option>
                      {selectFacility.map((facility) => (
                      <option key={facility.id} value={facility.facility}>
                        FACILITY {facility.facility}
                      </option>
                    ))}
                      {userFacilities.map((facility) => (
                        <option key={facility.id} value={facility.id}>
                          {facility.shortName}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <select>
                      <option>Loading Facilities...</option>
                    </select>
                  )}
                </label>
              </div> */}
            </div>
          </div>
        </div>
        <div
          className="container border"
          style={{
            borderBottomLeftRadius: "25px",
            borderBottomRightRadius: "25px",
            backgroundColor: "white",
            //   minHeight: "240px",
          }}
        >
          {loader ? (
            <div
              className="h-100 w-100 align-items-center d-flex my-3"
              style={{
                minHeight: "240px",
              }}
            >
              <Loader />
            </div>
          ) : (
            <>
              <div>
                <DashboardSummary scheduleData={scheduleData} />
              </div>
              {/* Replace scheduleData.map by appointments.map */}
              {/* {searchInDashboard(scheduleData).map((appointment, index) => {
                return (
                  <div key={index} className="border-bottom">
                    <div className="card-body d-flex align-items-center justify-content-between py-3">
                      <div className="col-2 col-md-2 col-sm-2 text-left">
                        <img
                          src={ClientProfile}
                          alt="client"
                          style={{ width: "60px", height: "60px" }}
                        />
                      </div>
                      <div className="col-8 col-md-8 col-sm-8">
                        <p className="h5 m-0">
                          {appointment.lastName}, {appointment.firstName}
                        </p>
                        <p style={{ fontSize: "16px" }}>
                          {moment(appointment.start).format("hh:mm A") +
                            " - " +
                            moment(appointment.end).format("hh:mm A")}
                        </p>
                        <p style={{ fontSize: "14px", fontWeight: 500 }}>
                          <NavLink
                            to={`/patient?page=1&patientId=${appointment.residentId}&tab=2`}
                            className="landing-link orange-1"
                          >
                            Treatment Summary
                          </NavLink>
                        </p>
                      </div>
                      <div className="col-2 col-md-2 col-sm-2 d-flex text-center justify-content-end">
                        {appointment.isUrgent ? (
                          <img
                            src={Alert}
                            alt="alert"
                            style={{ width: "30px", height: "30px" }}
                          />
                        ) : null}
                        {appointment.status === "completed" ? (
                          <img
                            src={Check}
                            alt="check"
                            style={{ width: "30px", height: "30px" }}
                          />
                        ) : (
                          <img
                            className="ml-1"
                            src={Pending}
                            alt="pending"
                            style={{ width: "30px", height: "30px" }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })} */}

              {/* <div className="text-right align-items-center p-2">
                <p style={{ fontWeight: 500 }}>
                  <NavLink to="/schedules" className="landing-link orange-1">
                    View Schedule
                  </NavLink>
                </p>
              </div> */}
            </>
          )}
        </div>
      </div>

      {adminRole || facilityManagerRole || careHomeManagerRole ? (
        <LovedOneRequest />
      ) : null}
    </>
  );
};

export default Appointments;
