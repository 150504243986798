/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./landing.style.css";
import moment from "moment";

import * as api from "../../../api";
import { useHistory } from "react-router-dom";
import Appointments from "./Appointments";
// import LandingPageProfile from "./LandingPageProfile";
import Events from "./Events";
import Tasks from "./Tasks";
import LovedOneRequest from "./LovedOneRequest";
import Notifications from "./Notifications";
import UserRoles from "../../../role";

const LandingPage = ({ notificationData, notificationLoader }) => {
  // const { authState, oktaAuth } = useOktaAuth();
  // const accessToken = authState.accessToken;
  const authState = JSON.parse(localStorage.getItem("auth0-token-storage"));
  const accessToken = authState?.access_token;
  const { careHomeManagerRole } = UserRoles();

  // For Schedules
  const [scheduleData, setScheduleData] = useState([]);
  const [dateContext] = useState(moment());
  const [loader, setLoader] = useState(true);
  const history = useHistory();
  const [searchItem] = useState("");
  const [searchParam] = useState(["lastName", "firstName", "residentId"]);

  const searchInDashboard = (items) => {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem]
            .toString()
            .toLowerCase()
            .indexOf(searchItem.toLowerCase()) > -1
        );
      });
    });
  };

  const handleExpiration = () => {
    localStorage.removeItem("auth0-token-storage");
    localStorage.removeItem("user-claims");
    history.push("/login");
  };

  // const urgentPatients = scheduleData.filter((a) => {
  //   return a.isUrgent === true;
  // });

  // const differentFacilities = [
  //   ...new Set(scheduleData.map((f) => f.facilityId)),
  // ];

  useEffect(() => {
    const getSchedule = async () => {
      setLoader(true);
      const dateFrom = `${dateContext.format("YYYY")}-${dateContext.format(
        "MM"
      )}-1`;
      const dateTo = `${dateContext.format("YYYY")}-${dateContext.format(
        "MM"
      )}-${dateContext.daysInMonth()}`;
      try {
        const data = await api.getAppointmentsToday(
          dateFrom,
          dateTo,
          accessToken
        );
        const schedule = data.data.data.data;
        // const mergedScheduleInfo = [];
        // schedule.forEach((schedule) => {
        //   schedule.ResidentsToBeTreated.forEach((resident) => {
        //     mergedScheduleInfo.push({
        //       firstName: resident.FirstName,
        //       lastName: resident.LastName,
        //       facilityId: schedule.FacilityId,
        //       isUrgent: resident.NeedsUrgentTreatment,
        //       start: resident.StartDateTime,
        //       end: resident.EndDateTime,
        //       residentId: resident.ResidentId,
        //     });
        //   });
        // });
        // console.log("schedule", mergedScheduleInfo);
        // setScheduleData(mergedScheduleInfo);
        setScheduleData(schedule);
        setLoader(false);
      } catch (error) {
        console.log("get schedules error");
        if (
          error?.response?.status === 401 &&
          error?.response?.data?.errors?.length > 0 &&
          error?.response?.data?.errors[0]?.message === "Invalid Token"
        ) {
          handleExpiration();
        }
        setLoader(false);
      }
    };

    getSchedule();
  }, [dateContext, accessToken]);

  // const handleExpiration = () => {
  //   // oktaAuth.signOut();
  //   localStorage.removeItem("auth0-token-storage");
  //   history.push("/login");
  // };

  return !accessToken ? (
    handleExpiration()
  ) : (
    <>
      <div className="container" style={{ marginBottom: "100px" }}>
        <div className="landing-bg">
          <div className="landing my-4 py-4">
            {/* <div className="row">
              <div className="ml-5 mb-4">
                <h5 style={{ fontWeight: "600", color: "#545554" }}>
                  You have {scheduleData.length} appointments in{" "}
                  {differentFacilities.length} different facilities,{" "}
                  {urgentPatients.length} of the patients are urgent.
                </h5>
                <NavLink to="/schedules" className="landing-link h5 orange-1">
                  See schedule?
                </NavLink>
              </div>
            </div> */}
            {/* <div className="col-12 col-sm-8 col-lg-4">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Search first name, last name or id"
                  value={searchItem}
                  onChange={(e) => setSearchItem(e.target.value)}
                />
              </div>
            </div> */}
            <div className="row pb-5 d-flex justify-content-between">
              {/* Appointments */}
              <div className="col-12 col-lg-8 my-2">
                <Appointments
                  scheduleData={scheduleData}
                  loader={loader}
                  searchInDashboard={searchInDashboard}
                />
              </div>

              <div className="col-12 col-lg-4 my-2">
                <Notifications
                  notificationData={notificationData}
                  notificationLoader={notificationLoader}
                />
                {careHomeManagerRole ? null : <Tasks />}
                <Events />
                {/* <AddLovedOne /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
