import React from "react";

const NotFound = () => {
  return (
    <div className="container">
      <div className="py-4">
        <h1>PAGE NOT FOUND</h1>
      </div>
    </div>
  );
};

export default NotFound;
