import { CREATE_USER, CREATE_USER_ERROR } from '../constants/actionsTypes'

const user = (user = [], action) => {
  switch (action.type) {
    case CREATE_USER:
      return {
        ...user,
        response: action.response
      }
    case CREATE_USER_ERROR:
      return {
        ...user,
        response: {
          status: action.response
        }
      }
    default:
      return user
  }
}

export default user
