import React from "react";
import {
  Spinner
} from "react-bootstrap";
const Loader = ({ variant }) => {
  return (
    <div className="text-center my-auto w-100">
      <Spinner className={"spinner-border text-secondary text-center"} variant={variant ?? "secondary"} role="status" size="sm">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );
};

export default Loader;
