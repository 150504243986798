//packages
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

//Okta related packages

import * as api from "../../../api";

//assets needed
import Loader from "../../reusables/Loader";
// import AlertsTop from "../../reusables/AlertsTop";
import Select from "react-select";

//start of function for the component
const UpdateUser = () => {
  // get the _id from the URL
  const { _id } = useParams();

  //okta stuff
  // const { authState } = useOktaAuth();
  const authState = JSON.parse(localStorage.getItem("auth0-token-storage"));
  const accessToken = authState?.access_token;

  const [loaderText, setLoaderText] = useState("");

  const [facilityOptions, setFacilityOptions] = useState([]);
  const [selectedFacilites, setSelectedFacilites] = useState([]);

  const [roleOptions, setRoleOptions] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);

  // const trueFalseAuth = authState.isAuthenticated;
  // console.log(trueFalseAuth);
  // const accessToken = authState.accessToken;
  // console.log(accessToken);

  //useState Returns a stateful value, and a function to update it.
  //the stateful value is what you'll use later when you render via map method (inside return)
  //React components has a built-in state object. The state object is where you store property values that belongs to the component. When the state object changes, the component re-renders.
  const [user, setUser] = useState({
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    nickName: "",
    email: "",
    zipCode: "",
    roleIds: [],
    facilityIds: [],
  });

  const [loader, setLoader] = useState(false);

  // const [error, setAlert] = useState({
  // 	errorPresent: false,
  // 	errorText: "",
  // 	bsClass: "",
  // });

  //destructure above so you can access patient.username by using only "username", and so on. These are empty initially as can be seen above.
  const { title, firstName, middleName, lastName, nickName, email, zipCode } =
    user;

  //to trigger when typing on a form input
  const onInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value }); //basically you're setting the patient object to whatever is being typed in the form as they key in the key-value pair matches the "name" in the form. And this will be the new value of object "patient" to be used below in axios
  };

  let history = useHistory();

  const onSubmit = async (e) => {
    e.preventDefault(); //to prevent 'Submit' button to submit and refresh page
    setLoaderText("Updating user info...");
    setLoader(true);
    console.log("selected facilites", selectedFacilites);

    let userFacilites = [];
    let userRoles = [];
    let postData;
    selectedFacilites.forEach((facility) => {
      userFacilites.push(facility.value);
    });

    selectedRoles.forEach((role) => {
      userRoles.push(role.value);
    });

    console.log("facilities to push", userFacilites);
    postData = {
      firstName: user.firstName,
      lastName: user.lastName,
      middleName: user.middleName,
      title: user.title,
      nickName: user.nickName,
      zipCode: user.zipCode,
      roleIds: userRoles,
      facilityIds: userFacilites,
    };

    console.log("postdata", postData);

    try {
      await api.updateUser(postData, _id, accessToken);
      setLoader(false);
      history.push("/users");
    } catch (error) {
      console.log("error updating a user", error);
      setLoader(false);
      if (
				error?.response?.status === 401 &&
				error?.response?.data?.errors?.length > 0 &&
				error?.response?.data?.errors[0]?.message === "Invalid Token"
			) {
				handleExpiration();
			}
    }
  };

  const getAllRoles = async (user) => {
    try {
      const data = await api.fetchRoles();

      const allRoles = data.data;

      let options = [];
      let currentRoles = [];

      allRoles.forEach((role) => {
        options.push({
          value: role._id,
          label: role.name,
        });

        user.roleIds.forEach((userRoles) => {
          console.log("current role iteration", userRoles);
          console.log("current role all roles", role.name);
          if (userRoles === role._id) {
            currentRoles.push({
              value: role._id,
              label: role.name,
            });
          }
        });
      });

      setRoleOptions(options);
      setSelectedRoles(currentRoles);
    } catch (error) {
      console.log("there was an error getting all roles", error);
      if (
				error?.response?.status === 401 &&
				error?.response?.data?.errors?.length > 0 &&
				error?.response?.data?.errors[0]?.message === "Invalid Token"
			) {
				handleExpiration();
			}
    }
  };

  useEffect(() => {
    const getAllFacilities = async (user) => {
      try {
        setLoader(true);
        const data = await api.fetchFacilities(accessToken);
        console.log("current facilities llength", data.data.data.data.length);
        const facilities = data.data.data.data;

        let options = [];
        let currentFacilities = [];

        facilities.forEach((facility) => {
          options.push({
            value: facility.facilityId,
            label: facility.facilityShortName,
          });

          user.facilityIds.forEach((userFacility) => {
            if (Number(userFacility) === facility.facilityId) {
              currentFacilities.push({
                value: facility.facilityId,
                label: facility.facilityShortName,
              });
            }
          });
        });

        console.log("users current facilities", user.facilityIds);

        console.log("facilities options", facilityOptions);
        setFacilityOptions(options);
        setSelectedFacilites(currentFacilities);
      } catch (error) {
        console.log("error getting facilites", error);
        setLoader(false);
        if (
          error?.response?.status === 401 &&
          error?.response?.data?.errors?.length > 0 &&
          error?.response?.data?.errors[0]?.message === "Invalid Token"
        ) {
          handleExpiration();
        }
      }
    };

    const getCurrentUser = async () => {
      try {
        setLoaderText("Getting user info...");
        setLoader(true);
        const response = await api.getUser(_id, accessToken);
        let data = response.data.data;

        await getAllRoles(data);
        await getAllFacilities(data);

        // setPatient({ ...data, random_words: data.random_words.toString() });
        setUser(data);
        setLoader(false);
      } catch (error) {
        console.log("something went wrong", error);
        setLoader(false);
        if (
          error?.response?.status === 401 &&
          error?.response?.data?.errors?.length > 0 &&
          error?.response?.data?.errors[0]?.message === "Invalid Token"
        ) {
          handleExpiration();
        }
      }
    };
    getCurrentUser();
    // eslint-disable-next-line
  }, [_id, accessToken]);

  const handleExpiration = () => {
		localStorage.removeItem("auth0-token-storage");
		localStorage.removeItem("user-claims");
		history.push("/login");
	};

  //Below is the html component to render the component plus latest values from the variables in the state above
  return (
    <div className="container">
      <div className="py-4">
        <h1>Edit User</h1>
        {/* {error.errorPresent ? (
					<AlertsTop text={error.errorText} bsClass={error.bsClass} />
				) : (
					""
				)} */}
      </div>

      <div className="card border shadow p-4 my-3">
        {loader ? (
          <div className="text-center">
            <Loader />
            <p>{loaderText}</p>
          </div>
        ) : (
          <form onSubmit={(e) => onSubmit(e)}>
            <div className="form-row">
              <div className="form-group">
                <label className="col-form-label-sm" htmlFor="Title">
                  Title
                </label>
                <select
                  className="form-control form-control-sm"
                  id="Title"
                  name="title"
                  onChange={(e) => onInputChange(e)}
                  value={title}
                >
                  <option value="Mr">Mr.</option>
                  <option value="Mrs">Mrs.</option>
                  <option value="Ms">Ms.</option>
                </select>
              </div>
              <div className="form-group">
                <label className="col-form-label-sm" htmlFor="FirstName">
                  First Name
                </label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="firstName"
                  onChange={(e) => onInputChange(e)}
                  value={firstName}
                />
              </div>
              <div className="form-group">
                <label className="col-form-label-sm" htmlFor="LastName">
                  Last Name
                </label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="lastName"
                  onChange={(e) => onInputChange(e)}
                  value={lastName}
                />
              </div>
              <div className="form-group">
                <label className="col-form-label-sm" htmlFor="KnownAs">
                  Middle Name
                </label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="middleName"
                  onChange={(e) => onInputChange(e)}
                  value={middleName}
                />
              </div>
              <div className="form-group">
                <label className="col-form-label-sm" htmlFor="KnownAs">
                  Nick Name
                </label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="nickName"
                  onChange={(e) => onInputChange(e)}
                  value={nickName}
                />
              </div>
              <div className="form-group">
                <label className="col-form-label-sm" htmlFor="KnownAs">
                  Email
                </label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="email"
                  onChange={(e) => onInputChange(e)}
                  value={email}
                  disabled
                />
              </div>
              <div className="form-group">
                <label className="col-form-label-sm" htmlFor="KnownAs">
                  Zip Code
                </label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="zipCode"
                  onChange={(e) => onInputChange(e)}
                  value={zipCode}
                />
              </div>
            </div>
            <div className="form-row mb-3">
              <div className="form-group col">
                <label className="col-form-label-sm" htmlFor="facilities">
                  Roles
                </label>
                <Select
                  isMulti
                  options={roleOptions}
                  value={selectedRoles}
                  onChange={(selected) => setSelectedRoles(selected)}
                />
              </div>
            </div>
            <div className="form-row mb-3">
              <div className="form-group col">
                <label className="col-form-label-sm" htmlFor="facilities">
                  Facilities
                </label>
                <Select
                  isMulti
                  options={facilityOptions}
                  value={selectedFacilites}
                  onChange={(selected) => setSelectedFacilites(selected)}
                />
              </div>
            </div>
            <button className="btn btn-primary btn-block">Edit user</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default UpdateUser;
