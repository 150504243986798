import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const ContactSupport = () => {
	const history = useHistory();

  // useEffect(() => {
  //   window.__lc = window.__lc || {};
  //   window.__lc.license = 16086690;

  //   function initLiveChat() {
  //     var n = document.createElement("script");
  //     n.async = true;
  //     n.type = "text/javascript";
  //     n.src = "https://cdn.livechatinc.com/tracking.js";
  //     document.head.appendChild(n);
  //   }

  //   if (!window.__lc.asyncInit) {
  //     initLiveChat();
  //     window.__lc.asyncInit = true;
  //   }
  // }, []);

  return (
    <>
      <div
        className="d-flex align-items-center mt-2 col-sm-2 col-xl-1"
        style={{ cursor: "pointer", color: "#f36917" }}
        onClick={() => history.goBack()}
      >
        <p>&lt; Back</p>
      </div>
      <div className="container-fluid mt-3">
        <h1 className="p-4 h6">
          To contact support please send an email to{" "}
          <a href="mailto:support@hapihealthcare.com?subject=Reschedule Appointment">
            support@hapihealthcare.com
          </a>
        </h1>
        <div>
          <iframe
            src="https://secure.livechatinc.com/customer/action/open_chat?license_id=16086690"
            width="800"
            height="650"
            frameBorder="0"
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default ContactSupport;
