import { combineReducers } from "redux";
import loved1s from "./loved1s";
import roles from "./roles";
import facilities from "./facilities";
import user from "./user";

export default combineReducers({
  user,
  roles,
  facilities,
  loved1s,
});
