import { useState, useEffect } from "react";

const usePracticeHook = () => {
  const userClaims = JSON.parse(localStorage.getItem("user-claims"));
  const userPractice = userClaims && userClaims.practice;
  const [isPhysiotherapist, setIsPhysiotherapist] = useState(false);
  const [isDietetics, setIsDietetics] = useState(false);
  const [isSpeechPathologist, setIsSpeechPathologist] = useState(false);
  const [isGeneralPractitioner, setIsGeneralPractitioner] = useState(false);

  useEffect(() => {
    if (
      userPractice &&
      userPractice.some((data) => data.name === "physiotherapist")
    ) {
      setIsPhysiotherapist(true);
    } else {
      setIsPhysiotherapist(false);
    }

    if (
      userPractice &&
      userPractice.some((data) => data.name === "diatetics")
    ) {
      setIsDietetics(true);
    } else {
      setIsDietetics(false);
    }

    if (
      userPractice &&
      userPractice.some((data) => data.name === "speech_pathology")
    ) {
      setIsSpeechPathologist(true);
    } else {
      setIsSpeechPathologist(false);
    }

    if (
      userPractice &&
      userPractice.some((data) => data.name === "general_practitioner")
    ) {
      setIsGeneralPractitioner(true);
    } else {
      setIsGeneralPractitioner(false);
    }
  }, [userPractice]);

  const hasPhysioPractice = isPhysiotherapist ? true : false;
  const hasDiatecianPractice = isDietetics ? true : false;
  const hasSpeechPathologistPractice = isSpeechPathologist ? true : false;
  const hasGeneralPractice = isGeneralPractitioner ? true : false;

  return {
    hasPhysioPractice,
    hasDiatecianPractice,
    hasSpeechPathologistPractice,
    hasGeneralPractice,
  };
};

export default usePracticeHook;
