import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ children, ...rest }) => {
	const isAuthenticated = () => {
		const authState = JSON.parse(
			localStorage.getItem("auth0-token-storage")
		);
		try {
			if (authState?.access_token) {
				return true;
			} else {
				return false;
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Route
			{...rest}
			render={() =>
				isAuthenticated() ? children : <Redirect to="/login" />
			}
		/>
	);
};

export default PrivateRoute;
