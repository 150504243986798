//import React from "react";
import React from "react";
import { useHistory } from "react-router-dom";

//images
import logo from "../../assets/images/hapiyouicon.png";

const Welcome = () => {
  const history = useHistory();

  // const { authState, oktaAuth } = useOktaAuth();
  // const accessToken = authState.accessToken;
  const authState = JSON.parse(localStorage.getItem("auth0-token-storage"));
  const accessToken = authState?.access_token;
  console.log(authState);

  const handleExpiration = () => {
    // oktaAuth.signOut();
    localStorage.removeItem("auth0-token-storage");
    localStorage.removeItem("user-claims");
    history.push("/login");
  };

  return !accessToken ? (
    handleExpiration()
  ) : (
    <div className="container-fluid my-2 rounded welcome-screen-image hero">
      <div className=" ml-4 text-center w-50">
        <img className="mb-3 hero-logo" src={logo} alt="happy you logo" />
        <p className="h4 text-left hero-text">
          HapiYou is a digital platform that connects you, the patient, with
          medical professionals for same day healthcare appointments and tracks
          your health care progression, educating you along the way. Putting you
          in control of your health and wellbeing.
        </p>
      </div>
    </div>
  );
};

export default Welcome;
