import { FETCH_FACILITIES, CREATE_FACILITY, UPDATE_FACILITY, DELETE_FACILITY } from "../constants/actionsTypes";

const facilities = (facilities = [], action) => {
    switch (action.type) {
        case FETCH_FACILITIES:
            return action.facilities;
        case CREATE_FACILITY:
            return [...facilities, action.facilities];
        case UPDATE_FACILITY:
            return facilities.map((facility) =>
                facility._id === action.facilities._id ? action.facilities : facilities
            );
        case DELETE_FACILITY:
            return facilities.filter((facility) => facility._id !== action.facilities);
        default:
            return facilities;
    }
};

export default facilities;