import React from 'react';
import { usePagination, DOTS } from './usePagination';
import './pagination.css';

const Pagination = props => {
    const {
        changeURL,
        siblingCount = 1,
        currentPage,
        totalCount,
        pageSize,
        paramState,
        getAllPatientsLoader,
        getNowPatientsLoader,
        inactive
    } = props;

    const paginationRange = usePagination({
        totalCount,
        currentPage,
        siblingCount,
        pageSize
    });

    if (currentPage === 0 || !paginationRange || paginationRange.length < 2) {
        return null;
    }

    const onNext = (e) => {
        changeURL(e, null, currentPage + 1, paramState.tab, paramState.facility, paramState.filter, paramState.date);
    };

    const onPrevious = (e) => {
        changeURL(e, null, currentPage - 1, paramState.tab, paramState.facility, paramState.filter, paramState.date);
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    
    return (!getAllPatientsLoader && !getNowPatientsLoader && !inactive && <div className="container">
        <ul
            className="pagination justify-content-center"
        >
            {
                currentPage !== 1 ? (
                    <li
                        className="page-item"
                        onClick={onPrevious}
                    >
                        <span className="page-link orange-1">Previous</span>
                    </li>
                ) : null
            }

            {paginationRange && paginationRange.map((pageNumber, index) => {

                // If the pageItem is a DOT, render the DOTS unicode character
                if (pageNumber === DOTS) {
                    return <li key={index} className="page-item dots"><span href="#" className="page-link">&#8230;</span></li>;
                }

                // Render our Page Pills
                return (
                    <li
                        key={index}
                        className="page-item"
                        onClick={(e) => changeURL(e, null, pageNumber, paramState.tab, paramState.facility, paramState.filter, paramState.date)}
                    >
                        <span href="#" className={`page-link orange-1 ${pageNumber === currentPage ? "orange-bg-1 text-light" : ""}`}>{pageNumber}</span>
                    </li>
                );
            })}
            {
                currentPage !== lastPage ? (
                    <li
                        className="page-item"
                        onClick={onNext}
                    >
                        <span className="page-link orange-1">Next</span>
                    </li>
                ) : null
            }
        </ul>
    </div>
    );
};

export default Pagination;