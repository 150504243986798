import React, { useState, useEffect } from "react";
import * as api from "../../../api";

const ResidentDetails = ({ residentId }) => {
  const authState = JSON.parse(localStorage.getItem("auth0-token-storage"));
  const accessToken = authState?.access_token;
  const [residentInfo, setResidentInfo] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const getResidentDetails = async () => {
      try {
        setLoader(true);
        const res = await api.getPatient(residentId, accessToken);
        const patient = res.data.data;
        setResidentInfo(patient.Fullname);
        setLoader(false);
      } catch (error) {
        console.log(error);
        setLoader(false);
      }
    };

    getResidentDetails();
  }, []);
  
  return <>{!loader && <>{residentInfo}</>}</>;
};

export default ResidentDetails;
