import React from "react";
import { useHistory } from "react-router-dom";

const HomeDashboard = () => {
  // const { authState, oktaAuth } = useOktaAuth();
  const authState = JSON.parse(localStorage.getItem("auth0-token-storage"));
  const history = useHistory();

  const handleExpiration = () => {
    // oktaAuth.signOut();
    localStorage.removeItem("auth0-token-storage");
    localStorage.removeItem("user-claims");
    history.push("/login");
  };

  return !authState.access_token ? (
    handleExpiration()
  ) : (
    <div className="container-fluid py-4 mt-3">
      <h1>Please clock in to view appointments</h1>
    </div>
  );
};

export default HomeDashboard;
