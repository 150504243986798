import React, { useState, useEffect } from "react";
import { useHistory, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import Loader from "../../reusables/Loader";
import ResidentDetails from "../notifications/ResidentDetails";

const Notifications = ({ notificationData, notificationLoader }) => {
  const history = useHistory();
  const authState = JSON.parse(localStorage.getItem("auth0-token-storage"));
  const accessToken = authState?.access_token;
  const [loader, setLoader] = useState(false);

  const handleExpiration = () => {
    localStorage.removeItem("auth0-token-storage");
    localStorage.removeItem("user-claims");
    history.push("/login");
  };

  return !accessToken ? (
    handleExpiration()
  ) : (
    <>
      <div className="container pb-0 mb-2">
        <div
          className="orange-bg-1 text-light py-3 my-0 d-flex justify-content-between align-items-center align-content-center align-self-center"
          style={{
            borderTopLeftRadius: "25px",
            borderTopRightRadius: "25px",
          }}
        >
          <div className="d-flex justify-content-center align-items-center m-auto align-content-center align-self-center">
            <FontAwesomeIcon
              icon={["fa", "bell"]}
              className="mr-2 my-align-items-center"
            />
            <h5 className="text-light align-items-center justify-content-center d-flex m-auto">
              Notifications
            </h5>
          </div>
        </div>
        <div
          className="p-0 bg-white border"
          style={{
            borderBottomLeftRadius: "25px",
            borderBottomRightRadius: "25px",
            backgroundColor: "white",
          }}
        >
          {!notificationLoader ? (
            <div
              className="mx-0"
              style={{
                minHeight: "240px",
              }}
            >
              <div>
                {notificationData && notificationData.length > 0 ? (
                  <>
                    {notificationData.slice(0, 4).map((data, index) => (
                      <NavLink
                        key={index}
                        to={`/allConsents/${data?.resident ?? "38028"}`}
                        className="d-flex border-bottom py-3 dashboardNotif"
                        style={{ cursor: "pointer" }}
                      >
                        <div className="col-11">
                          <p
                            className="orange-1"
                            style={{ fontSize: "0.8rem" }}
                          >
                            {/* <strong>{data.residentFullName}</strong> */}
                            <strong>
                              <ResidentDetails residentId={data.resident} />
                            </strong>
                          </p>
                          <p style={{ fontSize: "0.8rem", color: "#545554" }}>
                            {data.notes}
                          </p>
                          <p style={{ fontSize: "0.75rem", color: "#545554" }}>
                            <FontAwesomeIcon
                              icon={[
                                "fa",
                                `${
                                  data.notes.includes("Signed") ||
                                  data.notes.includes("signed")
                                    ? "edit"
                                    : "plus-circle"
                                }`,
                              ]}
                              className="mr-1 orange-1"
                            />{" "}
                            {moment(new Date(data.createdAt)).fromNow()}
                          </p>
                        </div>
                        {/* <div className="col-1">
                          <p className="text-center">
                            <svg
                              fill="#f36917"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 384 512"
                              style={{
                                height: "10px",
                                width: "10px",
                                cursor: "pointer",
                              }}
                            >
                              <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                            </svg>
                          </p>
                          <p>
                            <label className="overview-input2 mx-auto">
                              <input
                                className=""
                                type="radio"
                                style={{
                                  height: "10px",
                                  width: "10px",
                                  cursor: "pointer",
                                }}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </p>
                        </div> */}
                      </NavLink>
                    ))}
                  </>
                ) : (
                  <>
                    <div
                      className="h-100 w-100 align-items-center d-flex text-center justify-content-center my-auto"
                      style={{
                        fontSize: "15px",
                        letterSpacing: "1px",
                        fontWeight: "bolder",
                        opacity: 0.2,
                        minHeight: "240px",
                        backgroundColor: "rgba(0, 0, 0, 0.07)",
                        borderRadius: "25px",
                      }}
                    >
                      No Notifications Available
                    </div>
                  </>
                )}
                {notificationData.length > 3 && (
                  <div className="my-1">
                    <NavLink to="/notifications?page=1">
                      <p
                        className="text-center orange-1 see-all"
                        style={{ cursor: "pointer", fontSize: "0.8rem" }}
                      >
                        See all
                      </p>
                    </NavLink>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="my-3">
              <Loader />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Notifications;
