import { FETCH_ROLES } from "../constants/actionsTypes";

const roles = (roles = [], action) => {
    switch (action.type) {
        case FETCH_ROLES:
            return action.roles;
        default:
            return roles;
    }
};

export default roles;