import React from "react";
import { NavLink } from "react-router-dom";
import "./support.style.css";
import { useHistory } from "react-router-dom";

//SVG icons
import Plus from "../../../assets/svg/Support/Overview/Plus.svg";

// Font Awesome Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const SupportRequests = () => {
  // const { authState, oktaAuth } = useOktaAuth();
  const authState = JSON.parse(localStorage.getItem("auth0-token-storage"));
  const accessToken = authState?.access_token;
  const history = useHistory();

  const handleExpiration = () => {
    // oktaAuth.signOut();
    localStorage.removeItem("auth0-token-storage");
    localStorage.removeItem("user-claims");
    history.push("/login");
  };

  return !accessToken ? (
    handleExpiration()
  ) : (
    <div className="container">
      <div className="support-overview-bg">
        <div className="support-ticket my-4 pb-4">
          <h1 className="h3 fw-bold text-light px-4 py-2 mb-4">
            Your support requests
          </h1>
          <div className="px-5">
            <div
              className="p-2 mb-3 shadow"
              style={{ border: "1px solid #f36917", borderRadius: "15px" }}
            >
              <div className="row align-items-center">
                <div className="col-md-2 text-center border-right">
                  <select className="custom-select" id="inputGroupSelect01">
                    <option selected className="text-center">
                      Status
                    </option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <div className="col-md-5">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search by category of ID"
                  />
                </div>
                <div className="col-md-5 text-right">
                  <button className="btn btn-secondary py-1 px-3 mx-1">
                    WEEK
                  </button>
                  <button className="btn btn-secondary py-1 px-3 mx-1">
                    MONTH
                  </button>
                  <button className="btn btn-secondary py-1 px-3 mx-1">
                    YEAR
                  </button>
                  <button className="btn btn-primary py-1 px-3 mx-1">
                    CUSTOM
                  </button>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end mb-4 ">
              <NavLink to="/support/add">
                <div className="d-flex">
                  <img
                    src={Plus}
                    alt="plus"
                    style={{ width: "22px", height: "22px" }}
                  />
                  <h6 className="ml-1 my-auto orange-1">MAKE SUPPORT TICKET</h6>
                </div>
              </NavLink>
            </div>

            <div className="row align-items-center mx-2 mb-4">
              <div className="col-lg-2 text-left">
                <h6 className="font-weight-bold">TICKET #</h6>
              </div>
              <div className="col-lg-3 text-center">
                <h6 className="font-weight-bold">CATEGORY</h6>
              </div>
              <div className="col-lg-3 text-center">
                <h6 className="font-weight-bold">REQUEST STATUS</h6>
              </div>
              <div className="col-lg-2 text-center">
                <h6 className="font-weight-bold">LAST UPDATED</h6>
              </div>
              <div className="col-lg-2 text-right">
                <h6 className="font-weight-bold">
                  <span className="orange-1"> 1-20 </span> of 300
                  <span>
                    <FontAwesomeIcon
                      icon={["fas", "angle-left"]}
                      className="orange-1 mx-1"
                    />
                  </span>
                  <span>
                    <FontAwesomeIcon
                      icon={["fas", "angle-right"]}
                      className="orange-1"
                    />
                  </span>
                </h6>
              </div>
            </div>

            <div id="accordion">
              <div className="card mb-2">
                <div
                  className="card-header"
                  style={{ borderRadius: "10px" }}
                  id="heading"
                >
                  <div className="row align-items-center">
                    <div className="col-lg-2 text-left">#12345</div>
                    <div className="col-lg-3 text-center">
                      Lorem ipsum dolot sit
                    </div>
                    <div className="col-lg-3 text-center">
                      <span
                        className="py-1 px-4 text-white"
                        style={{
                          backgroundColor: "#f36917",
                          borderRadius: "50px",
                        }}
                      >
                        Resolved
                      </span>
                    </div>
                    <div className="col-lg-2 text-center">05-18-22</div>
                    <div className="col-lg-2 text-right">
                      <button
                        className="btn btn-secondary px-4 justify-content-end"
                        data-toggle="collapse"
                        data-target="#collapse"
                        aria-expanded="true"
                        aria-controls="collapse"
                      >
                        VIEW
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  id="collapse"
                  className="collapse"
                  aria-labelledby="heading"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    Anim pariatur cliche reprehenderit, enim eiusmod high life
                    accusamus terry richardson ad squid. 3 wolf moon officia
                    aute, non cupidatat skateboard dolor brunch. Food truck
                    quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                    sunt aliqua put a bird on it squid single-origin coffee
                    nulla assumenda shoreditch et. Nihil anim keffiyeh
                    helvetica, craft beer labore wes anderson cred nesciunt
                    sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                    Leggings occaecat craft beer farm-to-table, raw denim
                    aesthetic synth nesciunt you probably haven't heard of them
                    accusamus labore sustainable VHS.
                  </div>
                </div>
              </div>
              <div className="card mb-2">
                <div
                  className="card-header"
                  style={{ borderRadius: "10px" }}
                  id="heading"
                >
                  <div className="row align-items-center">
                    <div className="col-lg-2 text-left">#54321</div>
                    <div className="col-lg-3 text-center">
                      Lorem ipsum dolot sit
                    </div>
                    <div className="col-lg-3 text-center">
                      <span
                        className="py-1 px-4 text-white"
                        style={{
                          backgroundColor: "#f36917",
                          borderRadius: "50px",
                        }}
                      >
                        Resolved
                      </span>
                    </div>
                    <div className="col-lg-2 text-center">05-18-22</div>
                    <div className="col-lg-2 d-flex justify-content-end">
                      <span
                        className="mr-3 my-auto text-white text-center"
                        style={{
                          height: "25px",
                          width: "25px",
                          lineHeight: "25px",
                          background: "#f36917",
                          borderRadius: "50%",
                          fontSize: "15px",
                        }}
                      >
                        1
                      </span>
                      <button
                        className="btn btn-secondary px-4 justify-content-end"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo"
                      >
                        VIEW
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="heading"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    Anim pariatur cliche reprehenderit, enim eiusmod high life
                    accusamus terry richardson ad squid. 3 wolf moon officia
                    aute, non cupidatat skateboard dolor brunch. Food truck
                    quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                    sunt aliqua put a bird on it squid single-origin coffee
                    nulla assumenda shoreditch et. Nihil anim keffiyeh
                    helvetica, craft beer labore wes anderson cred nesciunt
                    sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                    Leggings occaecat craft beer farm-to-table, raw denim
                    aesthetic synth nesciunt you probably haven't heard of them
                    accusamus labore sustainable VHS.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default SupportRequests;
