import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import * as api from "../../../api";
import Loader from "../../reusables/Loader";

function ClockIn({ setClockIn, getClockInStatusLoader }) {
  // const { authState } = useOktaAuth();
  const authState = JSON.parse(localStorage.getItem("auth0-token-storage"));
  const accessToken = authState?.access_token;
  const mapsApiKey = "AIzaSyBM-Dvkr-oOQlu4WNgZpCevlzzj7-ZobdU&q";
  const [postData, setPostData] = useState({
    facilityId: 380,
    latitude: 51.16570369999999, // latitude of agintil
    longitude: -0.7552394, // longitude of agintil
  });
  const [loader, setLoader] = useState(false);
  const history = useHistory();

  // const getLocation = (e) => {
  //   e.preventDefault();
  //   let _postData = {};

  //   function getPosition(pos) {
  //     _postData.latitude = pos.coords.latitude ?? null;
  //     _postData.longitude = pos.coords.longitude ?? null;
  //     setPostData(_postData);
  //   };

  //   if (navigator.geolocation) {
  //     _postData = {
  //       facilityId: 380,
  //       latitude: null,
  //       longitude: null,
  //       // description: "",
  //       // facilityShortName: "",
  //       // localTimeStamp: "",
  //     }

  //     navigator.geolocation.getCurrentPosition(getPosition);
  //   }
  // }

  const submitClockIn = async (e) => {
    e.preventDefault();
    if (!authState || !accessToken) return;
    setLoader(true);
    try {
      const clockIn = await api.postClockInAndOut(postData, accessToken);
      console.log("Clocked In Successfully!", clockIn);
      setClockIn((prev) => !prev);
      history.push("/landing-page");
    } catch (error) {
      console.log("Error Clocking In");
      console.log(error);
      if (
				error?.response?.status === 401 &&
				error?.response?.data?.errors?.length > 0 &&
				error?.response?.data?.errors[0]?.message === "Invalid Token"
			) {
				handleExpiration();
			}
    } finally {
      setLoader(false);
    }
  };

  const handleExpiration = () => {
		localStorage.removeItem("auth0-token-storage");
		localStorage.removeItem("user-claims");
		history.push("/login");
	};

  return (
    <>
      <div className="d-flex justify-content-center pr-2 ml-lg-4">
        <div className="clinician-clock d-flex flex-column py-2">
          <div className="py-2 text-center">
            <button
              className="clock-in-button rounded-lg"
              onClick={async (e) => await submitClockIn(e)}
            >
              {loader || getClockInStatusLoader ? (
                <div className="d-inline">
                  <Loader variant="light" />
                </div>
              ) : (
                "Clock In"
              )}
            </button>
          </div>
        </div>

        {/* <div
          className={
            popupVisible
              ? "popup-container position-absolute px-4 pt-3 pb-2 pb-1 d-flex flex-column show-popup"
              : "popup-container"
          }
        > */}
        {/* <form>
            {location &&
              location.latitude &&
              location.longitude &&
              userFacilities && (
                <>
                  <div className="d-flex align-items-center justify-content-between pb-2">
                    <span className="popup-header landing-link orange-1 font-weight-bold">
                      {currentDateTime} {time}
                    </span>
                  </div>
                  <div className="popup-note d-flex flex-column pb-3">
                    {selectFacility && (
                      <>
                        <p>
                          You wish to clock-in in{" "}
                          <span className="font-weight-bold">
                            {selectFacility}
                          </span>
                          . Is this correct?
                        </p>
                        <p>
                          If not, please select for the right facility you want
                          to clock in.
                        </p>
                      </>
                    )}
                  </div>
                  <div className="popup-select d-flex flex-row-reverse align-items-center pb-3">
                    {!facilityLoader ? (
                      <select
                        className="w-100"
                        value={selectFacility}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          SELECT FACILITY
                        </option>
                        {userFacilities &&
                          userFacilities.length > 0 &&
                          userFacilities.map((facility) => (
                            <option
                              key={facility.id}
                              value={facility.shortName}
                            >
                              {facility.shortName}
                            </option>
                          ))}
                      </select>
                    ) : (
                      <select className="w-100">
                        <option disabled>Loading...</option>
                      </select>
                    )}
                  </div>
                  <div className="popup-content">
                    <iframe
                      width="500"
                      height="200"
                      style={{ border: "0" }}
                      loading="lazy"
                      // allowfullscreen
                      // referrerpolicy="no-referrer-when-downgrade"
                      src={`https://www.google.com/maps/embed/v1/place?key=${mapsApiKey}=${location.latitude},${location.longitude}&zoom=14`}
                    ></iframe>
                  </div>
                  <div className="text-left pt-1 pl-3 d-flex justify-content-end my-2">
                    <button
                      className="mr-2 btn btn-secondary px-3"
                      // onClick={() => setPopupVisible("")}
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary px-4"
                      onClick={submitClockIn}
                    >
                      Okay
                    </button>
                  </div>
                </>
              )}
          </form> */}
        {/* </div> */}
      </div>
    </>
  );
}

export default ClockIn;
