import { useState, useEffect } from "react";

const UserRoles = () => {
  const userClaims = JSON.parse(localStorage.getItem("user-claims"));
  const userRoles = userClaims && userClaims.roles;
  const [isAdmin, setIsAdmin] = useState(false);
  const [isClinicalManager, setIsClinicalManager] = useState(false);
  const [isFacilityManager, setIsFacilityManager] = useState(false);
  const [isCareHomeManager, setIsCareHomeManager] = useState(false);
  const [isOtherServices, setIsOtherServices] = useState(false);

  useEffect(() => {
    if (userRoles && userRoles.some((data) => data.name === "admin")) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }

		if (
			userRoles &&
			userRoles.some((data) => data.name === "practitioner")
		) {
			setIsOtherServices(true);
		} else {
			setIsOtherServices(false);
		}

		if (
			userRoles &&
			userRoles.some((data) => data.name === "clinical-manager")
		) {
			setIsClinicalManager(true);
		} else {
			setIsClinicalManager(false);
		}

    if (
      userRoles &&
      userRoles.some((data) => data.name === "facility-manager")
    ) {
      setIsFacilityManager(true);
    } else {
      setIsFacilityManager(false);
    }

    if (
      userRoles &&
      userRoles.some((data) => data.name === "carehome-manager")
    ) {
      setIsCareHomeManager(true);
    } else {
      setIsCareHomeManager(false);
    }
  }, [userRoles]);

  const otherServicesRole = isOtherServices ? true : false;
  const adminRole = isAdmin ? true : false;
  const clinicalManagerRole = isClinicalManager ? true : false;
  const facilityManagerRole = isFacilityManager ? true : false;
  const careHomeManagerRole = isCareHomeManager ? true : false;

  return {
    otherServicesRole,
    adminRole,
    clinicalManagerRole,
    facilityManagerRole,
    careHomeManagerRole,
  };
};

export default UserRoles;
